import React, { useState, useEffect } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Spinner, ListGroup, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import AlertCancel from '../components/Alerts/AlertCancel';
import AlertNeutral from '../components/Alerts/AlertNeutral';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import { round } from '../components/Dashboard/DashboardOrders';
import CommentPrinter from '../components/subComponents/CommentPrinter';
import DateFormatter from '../components/subComponents/DateFormatter';
import InvoiceModal from '../components/subComponents/InvoiceModal';
import ModalBs from '../components/subComponents/ModalBs';
import PriceFormatter from '../components/subComponents/PriceFormatter';
import StatusTag from '../components/subComponents/StatusTag';
import { OrderDetailType } from '../Models/Orders.Model';
import { socketEventCallback } from '../services/webSocket.service';
import { RootState } from '../store';
import AlertInfo from '../components/Alerts/AlertInfo';

import {
  acceptOrder,
  finishOrder,
  getCargoTypeData,
  getOrderDetails,
  getPreferredDate,
  getTransportVehicleId,
  issueImageUploadLink,
  rejectOrders,
  issuePaymentLink,
  checkPaymentStatus,
  cancelOrder, updateExecutionDate, getCancellationCostsApply,
} from '../store/actions/OrdersAction';
import ModalCheckPayoutStatus from '../components/subComponents/ModalCheckPayoutStatus';
import AcceptedOrderPopup from "../components/subComponents/AcceptedOrderPopup";
import CancelOrderPopup from "../components/subComponents/CancelOrderPopup";

interface ErrorResponseData {
  message: string;
  errors?: {
    [key: string]: string[];
  };
}

interface CancellationCosts {
  id: number;
  with_cancellation_costs: boolean;
  cancellation_costs: number;
}

const OrderDetail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('checkout_session_id');

  const fileInputRef: any = React.useRef();
  const dispatch: any = useDispatch();
  const { id } = useParams() as any;

  const [orderDetails, setOrderDetails]: any = React.useState(null);
  const [noOrderFound, setNoOrderFound] = React.useState(false);
  const [showFinishPopup, setShowFinishPopup] = React.useState(false);
  const [secret, setSecret] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [error, setError] = React.useState('blank');
  const [orderSuccessMsg, setOrderSuccessMsg] = React.useState(false);
  const [orderFailMsg, setOrderFailMsg] = React.useState<any>('');
  const [cancellationCosts, setCancellationCosts] = React.useState<CancellationCosts | null>(null);
  const [showorderFailMsg, setShowOrderFailMsg] = React.useState(false);
  const [showRejectModal, setRejectModal] = React.useState(false);
  const [rejectMessage, setRejectMessage] = React.useState('');
  const [orderDateUpdate, setOrderDateUpdate] = React.useState('');
  const [cargoTypeData, setCargoTypeData] = React.useState<any>();
  const [transportVehicleData, setTransportVehicleData] = React.useState<any>();
  const [routeInformation, setRouteInformation] = React.useState<any>();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [showCheckboxError, setShowCheckboxError] = React.useState(false);
  const [showPaymentStatusModal, setShowPaymentStatusModal] =
    React.useState(false);
  const [paymentStatusCheck, setPaymentStatusCheck] = React.useState({
    isLoading: true,
    isSuccess: false,
    message: 'checkingPaymentStatusBusyText',
  });
  // const [showRejectAlertSuccess,setShowRejectAlertSuccess] = React.useState(false);
  const [showRejectAlertCancel, setShowRejectAlertCancel] =
    React.useState(false);
  const [rejectOrderFailMsg, setRejectOrderFailMsg] =
    React.useState<any>('blank');
  const [showRejectInputError, setRejectInputError] = React.useState(false);

  const [acceptOrderObject, setAcceptOrderObject] = React.useState<any>({
    showAcceptOrderModal: false,
    acceptOrderDate: {},
    showLoader: false,
    acceptOrderError: null,
    acceptOrderCheckbox: true,
  });
  const [orderAccepted, setOrderAccepted] = React.useState(false);
  const navigate = useNavigate();
  const orderData = useSelector((state: RootState) => state.orderReducer);
  const [orderStatusChange, setOrderStatusChange] = React.useState(false);
  const initialLoader = useSelector(
    (state: RootState) => state.initialLoaderReducer
  );
  const isSocketConnected = useSelector(
    (state: RootState) => state.userReducer.isSocketConnected
  );

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  );

  React.useEffect(() => {
    if (id && sessionId && isLoaded) {
      setShowPaymentStatusModal(true);
      let attemptCount = 0; // Counter to track the number of attempts
      const maxAttempts = 30; // Maximum allowed attempts

      // Function to call checkPaymentStatus with retry logic and attempt limit
      const fetchPaymentStatus = () => {
        if (attemptCount >= maxAttempts) {
          // If the number of attempts reaches the limit, display a permanent error
          setPaymentStatusCheck({
            isLoading: false,
            isSuccess: false,
            message: 'paymentProcessingPermanentFail',
          });
          return; // Stop the retrying process
        }

        // Keep loading state active until final attempt is concluded
        setPaymentStatusCheck((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        setTimeout(() => {
          checkPaymentStatus(id, sessionId)
            .then((paymentProcessingResponse) => {
              setPaymentStatusCheck({
                isLoading: false,
                isSuccess: true,
                message: 'paymentProcessingSuccess',
              });

              setTimeout(() => {
                setShowPaymentStatusModal(false);
              }, 3000);

              setTimeout(() => {
                const url = new URL(window.location.href);

                // Access the URL's search parameters
                const params = url.searchParams;

                // Remove the 'checkout_session_id' parameter
                params.delete('checkout_session_id');

                // Set the modified search parameters back to the URL
                url.search = params.toString();

                // Redirect the user to the new URL without 'checkout_session_id'
                window.location.href = url.toString();
              }, 5000);
              // Success response received, update state accordingly
            })
            .catch((paymentProcessingErrorResponse) => {
              attemptCount += 1; // Increment the attempt counter on failure

              if (attemptCount < maxAttempts) {
                // Retry after 5 seconds on error, only if attempt limit not reached
                setTimeout(fetchPaymentStatus, 1000);
              } else {
                // Final attempt failed, show permanent error
                setPaymentStatusCheck({
                  isLoading: false,
                  isSuccess: false,
                  message: 'paymentProcessingPermanentFail',
                });
              }
            });
        }, 2500);
      };

      // Initiates the first call with a delay and potential retries
      fetchPaymentStatus();
    }
  }, [id, sessionId, isLoaded]);

  React.useEffect(() => {
    if (id && isSocketConnected) {
      // @ts-ignore
      window.Pusher.subscribe(`private-orders.${id}`).bind_global(
        socketEventCallback
      );
    }
  }, [id, isSocketConnected]);

  React.useEffect(() => {
    if (id && initialLoader.loaded) {
      dispatch(
        getOrderDetails(id, (status: boolean, response: OrderDetailType) => {
          setIsLoaded(true);
          if (status) {
            setOrderDetails(response.data);
            // dispatch(getPreferredDate(id));
            setRouteInformation(
              response.data.additional_data?.find(
                (obj: any) => obj.key === 'route_information'
              )
            );
            response.data.additional_data?.map((item) => {
              if (item.key === 'cargo_type_id')
                dispatch(
                  getCargoTypeData(
                    item.json_value.id,
                    (status3: boolean, response3: any) => {
                      if (status3) {
                        setCargoTypeData(response3.data);
                      }
                    }
                  )
                );
              else if (item.key === 'transportation_vehicle_id')
                dispatch(
                  getTransportVehicleId(
                    item.json_value.id,
                    (status2: boolean, response2: any) => {
                      if (status2) {
                        setTransportVehicleData(response2.data);
                      }
                    }
                  )
                );

              return 1;
            });
          } else {
            setNoOrderFound(true);
          }
        })
      );
    }
  }, [id, orderAccepted, initialLoader, orderStatusChange]);
  const closeRejectModal = () => {
    setRejectModal(false);
    setRejectMessage('');
    setRejectInputError(false);
  };
  const openRejectModal = () => setRejectModal(true);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelOrderError, setCancelOrderError] = useState('');
  const handleCancelOrderShowPopup = () => {
    setShowCancelModal(true);
    setCancellationCosts(null); // safe reset in case they switch between orders or anything

    getCancellationCostsApply(id).then((response) => {
      setCancellationCosts(response.data.data)
    })


  };

  const confirmCancelOrder = () => {
    dispatch(cancelOrder(id, (status: boolean, response: any) => {
      if (status) {
        // Handle successful cancellation
        setOrderStatusChange(!orderStatusChange);
        setShowCancelModal(false);
        // You might want to show a success message or redirect the user
      } else {
        // Handle cancellation failure
        setCancelOrderError('cancelOrderError');
      }
    }));
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
    setCancelOrderError('');
  };

  const togglePopup = () => setShowFinishPopup(!showFinishPopup);
  const handleFileChange = (uploadFile: any) => {
    if (!uploadFile) {
      return;
    }

    const file = uploadFile[0];
    if (file.size > 5242880){
      setError('errMsg');
    } else {
      setError('blank');
      dispatch(
        issueImageUploadLink(
          { id, type: file.type, size: file.size },
          (status: boolean, response: AxiosResponse) => {
            if (status) {
              axios
                .put(response.data.upload_url, file, {
                  headers: {
                    'Content-Type': file.type,
                    'Accept-Language': 'nl',
                  },
                })
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => console.log(err));
              setSecret(response.data.secret_id);
              // console.log('status,responsestatus,response==', status, response);
            }
          }
        )
      );
    }
    // console.log('Fsi', file.size, file.type);
  };

  const handleFinish = () => {
    if(!amount) {
      setError("order.finish.amountRequired");
      return;
    }

    let payment;
    if (amount.includes(',')) {
      payment = Number(amount.replace(',', ''));
    } else {
      payment = Number(amount) * 100;
    }

    finishOrder(
        id,
        secret ? { price: payment, attachment_id: [secret] } : { price: payment }
    )
        .then((response: AxiosResponse) => {
          setShowOrderFailMsg(false);
          setOrderSuccessMsg(true);
          togglePopup();
          setOrderStatusChange(!orderStatusChange);
        })
        .catch((exception: AxiosError) => {
          const data = exception.response?.data as ErrorResponseData;

          if (exception.response?.status === 422) {
            if (data.errors && 'attachment_id' in data.errors) {
              // If it contains 'attachment_id', show error message for file required
              setError('upload.invoice.fileRequired');
            } else {
              // Show the error message from the server
              setError(data.message || 'An error occurred');
            }
          } else {
            // Handle other status codes or network errors
            setError(data.message || 'An error occurred');
          }
        });
  };

  const handleRejectMessage = (em: string) => {
    setRejectMessage(em);
    if (em === '') setRejectInputError(true);
    else {
      setRejectInputError(false);
    }
  };
  const handleReject = () => {
    if (rejectMessage.trim() !== '') {
      dispatch(
        rejectOrders(
          id,
          { comment: rejectMessage },
          (status: boolean, response: AxiosResponse) => {
            if (status) {
              setShowRejectAlertCancel(false);
              // setShowRejectAlertSuccess(true)
              navigate('/orders');
            } else {
              // setShowRejectAlertSuccess(false)
              closeRejectModal();
              setShowRejectAlertCancel(true);
              setRejectOrderFailMsg(response);
            }
          }
        )
      );
    } else {
      setRejectInputError(true);
    }
  };

  const handleCustomerNotAnswered = () => {
    console.log('customer not answered phone')
    // TODO show success message and close the modal
    //  Api call to the backend to create callback request
  };

  const handleAcceptOrder = () => {
    dispatch(getPreferredDate(id));
    // setShowAcceptOrderModal(true);
    setAcceptOrderObject({ ...acceptOrderObject, showAcceptOrderModal: true });
  };
  const closeAcceptOrderModal = () => {
    setAcceptOrderObject({
      ...acceptOrderObject,
      showAcceptOrderModal: false,
      acceptOrderError: '',
      acceptOrderDate: {},
      acceptOrderCheckbox: true,
      showLoader: false,
    });
  };
  const handleSelect = (e: any) => {
    const data = e.target.value.split(',');
    if (data.length === 2) {
      const date = e.target.value.split(',');
      setAcceptOrderObject({
        ...acceptOrderObject,
        acceptOrderDate: {
          start_date: date[0],
          end_date: date[1],
        },
      });
      console.log(acceptOrderObject, ' accepting for non custom time and date');
    } else {
      const isoDateTime = e.target.value;
      const startDate = new Date(isoDateTime);
      const endDate = new Date(isoDateTime);
      startDate.setHours(startDate.getHours() + 5);
      endDate.setHours(endDate.getHours() + 6);
      const start_date = startDate.toISOString().replace('Z', '000Z');
      const end_date = endDate.toISOString().replace('Z', '000Z');
      setAcceptOrderObject({
        ...acceptOrderObject,
        acceptOrderDate: {
          start_date: start_date.split(',')[0],
          end_date: end_date.split(',')[0],
        },
      });
      console.log(acceptOrderObject, 'after accepting Custom Time and date');
    }
  };
  const handleCheckbox = (e: any) => {
    if (e.target.checked) {
      setShowCheckboxError(false);
      setAcceptOrderObject({
        ...acceptOrderObject,
        acceptOrderCheckbox: false,
      });
    } else
      setAcceptOrderObject({
        ...acceptOrderObject,
        acceptOrderCheckbox: true,
      });
  };
  const submitAcceptOrder = () => {
    if (acceptOrderObject.acceptOrderCheckbox && orderDetails.status.name === 'processing') {

      setShowCheckboxError(true)
    } else {
      const data =
        Object.keys(acceptOrderObject.acceptOrderDate).length === 0
          ? {
              preferred_date: {
                start_date: orderData.preferredDate[0].start_date,
                end_date: orderData.preferredDate[0].end_date,
              },
            }
          : { preferred_date: acceptOrderObject.acceptOrderDate };
      setAcceptOrderObject({
        ...acceptOrderObject,
        showLoader: true,
        acceptOrderCheckbox: true,
      });
      console.log(data);

      dispatch(async () => {
        try {
          if(orderDetails.status.name === 'processing') {
            const response = await acceptOrder(id, data);
            setAcceptOrderObject({
              ...acceptOrderObject,
              showLoader: false,
            });
          } else {
            const response = await updateExecutionDate(id, data);
            setOrderDateUpdate('orderDateUpdatedSuccess');
            const newPreferredDates = [acceptOrderObject.acceptOrderDate];

            // Update the state
            setOrderDetails((prevState: any) => ({
              ...prevState,
              preferred_dates: newPreferredDates,
            }));
          }


          closeAcceptOrderModal();
          setOrderAccepted(true);
        } catch (errorResponse: any) {
          // means we need to start a payment process for this user
          if (errorResponse.response?.status === 402) {
            console.log('Executing request for payment link');
            try {
              const response = await issuePaymentLink(
                id,
                window.location.origin +
                  '/orders/' +
                  id +
                  '?checkout_session_id=:identifier',
                window.location.origin +
                  '/orders/' +
                  id +
                  '?checkout_session_id=:identifier',
                data.preferred_date
              );

              const stripe = await stripePromise;
              const result = await stripe?.redirectToCheckout({
                sessionId: response.data.id,
              });
            } catch (paymentError) {
              console.error('Error during payment process:', paymentError);
            }
          }

          setAcceptOrderObject({
            ...acceptOrderObject,
            showLoader: false,
            acceptOrderError: errorResponse.response?.data.message,
            acceptOrderCheckbox: true,
          });
        }
      });
    }
  };

  return (
    <div>
      {isLoaded ? (
        <div className=" components-card">
          {noOrderFound ? (
            <div className="container p-3" style={{ background: 'white' }}>
              <AlertCancel
                id="noOrderFound"
                showButton={false}
                action={null}
                actionLabel="blank"
                fixed
              />
            </div>
          ) : (
            <div
              className="container py-4 px-5"
              style={{ background: 'white' }}
            >
              {orderDateUpdate && (
                  <AlertSuccess id={orderDateUpdate} fixed />
              )}
              {orderSuccessMsg && (
                <AlertSuccess id="orderSuccessMessage" fixed />
              )}
              {showorderFailMsg && (
                <AlertCancel
                  id="exportFailMessage"
                  showButton={false}
                  action={null}
                  actionLabel="blank"
                  fixed={false}
                  payload={{ msg: orderFailMsg }}
                />
              )}
              {showRejectAlertCancel && (
                <AlertCancel
                  id="exportFailMessage"
                  showButton={false}
                  action={null}
                  actionLabel="blank"
                  fixed={false}
                  payload={{ msg: rejectOrderFailMsg }}
                />
              )}
              <div className="row">
                <div
                  className="col-12 d-flex justify-content-center"
                  style={{ gap: '2%' }}
                >
                  <p className="orderDetailHeading mb-5">
                    <FormattedMessage id="order" />: {id}
                  </p>
                  {orderDetails?.status.name && (
                    <p style={{ textTransform: 'capitalize' }}>
                      {/* <strong>Status:</strong>{' '} */}
                      <StatusTag status={orderDetails.status.name} />
                    </p>
                  )}
                </div>
              </div>
              {orderDetails?.status?.name === 'missed' ? (
                <>
                  <div className="alert-box alert-info ">
                    <p className="some-info_blue">
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            clipPath="url(#f0la7sgvaa)"
                            stroke="#342fa8"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M8 14.666A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.333zM7.993 4.667h.014M8 7.333v4" />
                          </g>
                          <defs>
                            <clipPath id="f0la7sgvaa">
                              <path fill="#fff" d="M0 0h16v16H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <h1 className="orderDetailHeading mb-2">
                        <FormattedMessage
                          id="heading1"
                          defaultMessage="Mis je Volgende Kans Niet!"
                        />
                      </h1>
                      <div className="mb-2">
                        <FormattedMessage
                          id="intro"
                          defaultMessage="We hebben gezien dat je geïnteresseerd was in Bestelling #62677, maar het is je ontglipt. In de snelle wereld van Vandaag is timing alles — en wij zijn er om je vooruit te helpen. Hier is hoe je de volgende kans kunt grijpen:"
                        />
                      </div>

                      <ul className="mb-4 flex-column align-items-start">
                        <li>
                          <div className="orderDetailHeading mb-2">
                            <FormattedMessage
                              id="tips.title"
                              defaultMessage="Blijf Alert:"
                            />
                          </div>
                          <FormattedMessage
                            id="tips.content"
                            defaultMessage="Schakel directe meldingen in je instellingen in om als eerste te weten wanneer er bestellingen worden geplaatst die overeenkomen met jouw voorkeuren."
                          />
                        </li>
                        <li>
                          <div className="orderDetailHeading my-2">
                            <FormattedMessage
                              id="tips.title1"
                              defaultMessage="Snelle Reactie:"
                            />
                          </div>
                          <FormattedMessage
                            id="tips.content1"
                            defaultMessage="Snelheid is essentieel. Bestellingen kunnen snel worden geclaimd, dus wees klaar om direct te reageren zodra je een melding ontvangt."
                          />
                        </li>
                        <li>
                          <div className="orderDetailHeading my-2">
                            <FormattedMessage
                              id="tips.title2"
                              defaultMessage="Krijg een Voorsprong met LIDMAATSCHAP PROFESSIONAL:"
                            />
                          </div>
                          <FormattedMessage
                            id="tips.content2"
                            defaultMessage="Wil je een insider voordeel? Ons lidmaatschap professional geeft je vroegtijdige toegang tot bestellingen, zodat je altijd een stap voor blijft. Probeer het uit en ervaar het verschil!"
                          />
                        </li>
                      </ul>

                      <div className="mb-2">
                        <FormattedMessage
                          id="reviewText"
                          defaultMessage="Samen kunnen we ervoor zorgen dat je volgende claim een succes wordt. Neem een moment om je meldingsinstellingen te bekijken en overweeg onze [Subscription Name] om je reactietijd te verbeteren."
                        />
                      </div>
                      <h1 className="orderDetailHeading">
                        <FormattedMessage
                          id="heading2"
                          defaultMessage="Blijf Klaar, Blijf Voorop!"
                        />
                      </h1>
                    </p>
                  </div>

                  <div className="row">
                    <div className="orderDetailButtonGroup">
                      <a href="/subscriptions" target="_blank" rel="noreferrer">
                        <button
                          type="button"
                          className=" TypeNegative-Rest-medium"
                        >
                          <FormattedMessage id="order.button.sub" />
                        </button>
                      </a>
                      <a href="/settings" target="_blank" rel="noreferrer">
                        <button className=" Box-type-medium" type="button">
                          <FormattedMessage id="order.button.sett" />
                        </button>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex row  ">
                  <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className="customerDetails mb-5 additionalData">
                      <p className="orderDetailHeading mb-3">
                        <FormattedMessage id="customerDetails" />
                      </p>
                      {orderDetails?.name && (
                        <p>
                          <strong>
                            <FormattedMessage id="name" />:
                          </strong>{' '}
                          {orderDetails?.saluation} {orderDetails?.name}
                        </p>
                      )}
                      {orderDetails?.email && (
                        <p>
                          <strong>Email:</strong> {orderDetails?.email}
                        </p>
                      )}
                      {orderDetails?.phone && (
                        <p>
                          <strong>
                            <FormattedMessage id="telephone" />:
                          </strong>{' '}
                          {orderDetails?.phone}
                        </p>
                      )}
                      {orderDetails?.address && (
                        <p>
                          <strong>
                            <FormattedMessage id="address" />
                            :&nbsp;
                          </strong>
                          {orderDetails.address.street}&nbsp;
                          {orderDetails.address.street_number}&nbsp;
                          {orderDetails.address.city}&nbsp;
                          {orderDetails.address.zip_code}&nbsp;
                          <span style={{ textTransform: 'uppercase' }}>
                            {orderDetails.address.country}
                          </span>
                        </p>
                      )}
                      {orderDetails?.created_at && (
                        <div style={{ display: 'flex', gap: '3px' }}>
                          <p>
                            <strong>
                              <FormattedMessage id="bookingDate" />:
                            </strong>
                          </p>
                          <DateFormatter
                            date={orderDetails?.created_at}
                            showTime
                            showDifference
                          />
                        </div>
                      )}
                      {/* {orderDetails?.status.name && (
                    <p style={{ textTransform: 'capitalize' }}>
                      <strong>Status:</strong>{' '}
                      <StatusTag status={orderDetails.status.name} />
                    </p>
                  )} */}

                      {/* <p><strong><FormattedMessage id="status"/>:</strong> {orderDetails.status.name}</p> */}
                    </div>
                    <div className="additionalData">
                      {orderDetails?.additional_data?.map(
                        (singleData: any, idx: number) => {
                          if (
                            singleData.key === 'vehicle' &&
                            orderDetails?.department === 'vehicles'
                          ) {
                            const constructionYear = new Date(
                              singleData.json_value?.construction_year
                            );
                            return (
                              <div key={singleData.id}>
                                {idx === 0 && (
                                  <>
                                    <p className="orderDetailHeading mb-3">
                                      <FormattedMessage id="vehicleInformation" />
                                    </p>
                                    <div
                                      key={singleData.id}
                                      className="additionalData"
                                    >
                                      {singleData.json_value
                                        ?.formatted_plate && (
                                        <p>
                                          <strong>
                                            <FormattedMessage id="licensePlate" />
                                            :
                                          </strong>{' '}
                                          {
                                            singleData.json_value
                                              ?.formatted_plate
                                          }
                                        </p>
                                      )}
                                      {singleData.json_value?.brand.name && (
                                        <p>
                                          <strong>
                                            <FormattedMessage id="brand" />:
                                          </strong>{' '}
                                          {singleData.json_value?.brand.name}
                                        </p>
                                      )}
                                      {singleData.json_value?.model.name && (
                                        <p>
                                          <strong>Model:</strong>{' '}
                                          {singleData.json_value?.model.name}
                                        </p>
                                      )}
                                      {singleData.json_value
                                        ?.construction_year && (
                                        <p>
                                          <strong>
                                            <FormattedMessage id="constructionYear" />
                                            :
                                          </strong>{' '}
                                          {constructionYear.getFullYear()}
                                        </p>
                                      )}
                                      {singleData.json_value?.fuel.name && (
                                        <p>
                                          <strong>
                                            <FormattedMessage id="fuelType" />:
                                          </strong>{' '}
                                          {singleData.json_value?.fuel.name}
                                        </p>
                                      )}
                                      {singleData.json_value
                                        ?.mandatory_service_expiry_date && (
                                        <div
                                          className="d-flex"
                                          style={{ gap: '1%' }}
                                        >
                                          <p>
                                            <strong>
                                              <FormattedMessage id="apkExpirationDate" />
                                              :
                                            </strong>{' '}
                                          </p>
                                          <DateFormatter
                                            date={
                                              singleData.json_value
                                                ?.mandatory_service_expiry_date
                                            }
                                          />
                                        </div>
                                      )}
                                      <div className="col-4 mt-3 mb-3">
                                        {singleData.json_value.images?.map(
                                          (image: any) => {
                                            return (
                                              <div
                                                key={image.id}
                                                style={{
                                                  background: `url(${image.location})`,
                                                  height: '250px',
                                                  width: '394px',
                                                  backgroundSize: 'contain',
                                                  backgroundRepeat: 'no-repeat',
                                                }}
                                              />
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                          if (orderDetails?.department === 'couriers') {
                            return (
                              <div key={singleData.id}>
                                {idx === 0 && (
                                  <div className="orderDetailHeading mb-3">
                                    <FormattedMessage id="courrierBox1Title" />
                                  </div>
                                )}
                                <div>
                                  {singleData.key === 'cargo_type_id' && (
                                    <div>
                                      <strong>
                                        <FormattedMessage id="cargoType" />:{' '}
                                      </strong>{' '}
                                      {cargoTypeData?.name}
                                    </div>
                                  )}
                                  {singleData.key ===
                                    'transportation_vehicle_id' && (
                                    <div>
                                      <strong>
                                        <FormattedMessage id="typeCourier" />:{' '}
                                      </strong>{' '}
                                      {transportVehicleData?.name}{' '}
                                    </div>
                                  )}
                                  {singleData.key === 'dimensions' && (
                                    <div className="d-flex">
                                      <strong>
                                        <FormattedMessage id="dimensions" />
                                        :&nbsp;{' '}
                                      </strong>
                                      <div
                                        className="d-flex"
                                        style={{ gap: '2%' }}
                                      >
                                        <FormattedMessage id="width" />:{' '}
                                        {singleData.json_value.width}&nbsp;
                                        <FormattedMessage id="height" />:{' '}
                                        {singleData.json_value.height}&nbsp;
                                        <FormattedMessage id="length" />:{' '}
                                        {singleData.json_value.length}&nbsp;
                                        <FormattedMessage id="weight" />:{' '}
                                        {singleData.json_value.weight}
                                      </div>
                                    </div>
                                  )}
                                  {singleData.key === 'route_information' && (
                                    <div>
                                      <div>
                                        <strong>
                                          <FormattedMessage id="distance" />
                                          :&nbsp;
                                        </strong>
                                        {singleData.json_value.distance ? (
                                          <span>
                                            {round(
                                              singleData.json_value.distance /
                                                1000,
                                              1
                                            ).toFixed(1)}{' '}
                                            Km
                                          </span>
                                        ) : (
                                          <p>0 Km</p>
                                        )}
                                      </div>
                                      <div>
                                        <strong>
                                          <FormattedMessage id="travelTime" />
                                          :&nbsp;
                                        </strong>{' '}
                                        {Math.floor(
                                          singleData.json_value.duration / 60
                                        )}{' '}
                                        minutes
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          }

                          return 1;
                        }
                      )}
                      {routeInformation && (
                        <div className="d-flex my-3">
                          <div>
                            {true && (
                              <div className="orderDetailHeading mb-3">
                                <FormattedMessage id="courrierBox2Title" />
                              </div>
                            )}
                            {/* <strong>
                          <FormattedMessage id="fromLocation" />
                        </strong>{' '}
                        :  */}
                            <p style={{ width: '91%' }}>
                              {routeInformation?.json_value.origin.full}
                            </p>
                          </div>
                          <div>
                            {true && (
                              <div className="orderDetailHeading mb-3">
                                <FormattedMessage id="courrierBox3Title" />
                              </div>
                            )}
                            {/* <strong>
                          <FormattedMessage id="toLocation" />
                        </strong>{' '}
                        :  */}
                            <p style={{ width: '91%' }}>
                              {routeInformation?.json_value.destination.full}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {orderDetails?.comments &&
                      orderDetails?.comments.length !== 0 && (
                        <div className="my-5">
                          <p className="orderDetailHeading text-start">
                            <FormattedMessage id="comments" />
                          </p>
                          <div className="orderDetailCommentBox text-start py-1 px-4">
                            {orderDetails?.comments.map((comment: any) => {
                              return (
                                <CommentPrinter
                                  msg={comment.comment}
                                  //  name={comment.visibility}
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                    {orderDetails?.attachments &&
                      orderDetails?.attachments.map((attachment: any) => {
                        return (
                          <div
                            style={{ marginBottom: '90px' }}
                            key={attachment.id}
                          >
                            <div className="orderDetailHeading mb-3">
                              <FormattedMessage id="attachments" />
                            </div>
                            <div className="d-flex" style={{ gap: '2%' }}>
                              <div>
                                <div className="mb-1">
                                  <strong>
                                    <FormattedMessage id="name" />:
                                  </strong>{' '}
                                  {attachment.name}
                                </div>
                                <div>
                                  <strong>
                                    <FormattedMessage id="size" />:
                                  </strong>{' '}
                                  {attachment.filesize} bytes
                                </div>
                              </div>
                              <div>
                                <a
                                  type="button"
                                  className="Box-type"
                                  href={attachment.path}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FormattedMessage id="view" />
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12">
                    {orderDetails?.services && (
                      <>
                        <p className="orderDetailHeading mb-3">
                          <FormattedMessage id="services" />
                        </p>
                        <table className="table table-bordered text-center">
                          <thead className="table-secondary">
                            <tr>
                              <th
                                className="tableSecondaryheadingBorder"
                                style={{ width: '70%' }}
                              >
                                <FormattedMessage id="service" />
                              </th>
                              <th className="tableSecondaryheadingBorder w-30">
                                <FormattedMessage id="price" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetails?.services.map((service: any) => {
                              return (
                                <tr key={service.id}>
                                  <td className="w-70 orderDetailTableLabel">
                                    <p>
                                      {service.amount} {service.name}
                                    </p>
                                  </td>
                                  <td className="w-30">
                                    <p>
                                      <PriceFormatter
                                        amount={service.price}
                                        symbol={service.currency_code_iso}
                                      />
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                    {orderDetails?.meta?.total_price?.length > 0 && (
                      <div className="d-flex totalPaymentText">
                        <div style={{ textAlign: 'left', width: '70%' }}>
                          <p>
                            <FormattedMessage id="total" />
                          </p>
                        </div>
                        <div style={{ textAlign: 'center', width: '30%' }}>
                          <p>
                            <PriceFormatter
                              amount={orderDetails?.meta.total_price}
                              symbol={
                                orderDetails?.services[0]?.currency_code_iso
                              }
                            />
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="orderDetailPaymentContainer">
                      {orderDetails?.payment_method && (
                        <>
                          <p>
                            <strong>
                              {' '}
                              <FormattedMessage id="paymentMethod" />:
                            </strong>{' '}
                            <FormattedMessage
                              id={
                                orderDetails?.payment_method
                                  ? orderDetails.payment_method
                                  : 'blank'
                              }
                            />
                          </p>
                          <div className="orderDetailAlertBox mt-3">
                            {orderDetails?.payment_method === 'online' && (
                              <AlertSuccess id="onlinePayMessage" fixed />
                            )}
                            {orderDetails?.payment_method === 'partial' && (
                              <AlertNeutral id="partialPayMessage" fixed />
                            )}
                            {orderDetails?.payment_method === 'location' && (
                              <AlertNeutral id="onLocationPayMessage" fixed />
                            )}
                          </div>
                        </>
                      )}
                      <div>
                        {orderDetails.preferred_dates?.map(
                          (pd: any, idx: number) => {
                            const hasSelectedItem = pd.selected === true;
                            return (
                              <div>
                                {hasSelectedItem ? (
                                  <div>
                                    <div
                                      className="d-flex mt-1"
                                      style={{ gap: '2%' }}
                                    >
                                      <strong>
                                        {' '}
                                        <FormattedMessage id="executionDate" />
                                      </strong>{' '}
                                      :{' '}
                                      <div>
                                        <p>
                                          <DateFormatter date={pd.start_date} />
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex mt-1"
                                      style={{ gap: '2%' }}
                                    >
                                      <p>
                                        {' '}
                                        <FormattedMessage id="time" />:{' '}
                                      </p>
                                      <DateFormatter
                                        date={pd.start_date}
                                        hideDate
                                        showTime
                                        showDifference
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="mt-2">
                                    <strong>
                                      <p>
                                        <FormattedMessage id="preferredDate" />{' '}
                                        {idx + 1}
                                      </p>
                                    </strong>
                                    <div
                                      className="d-flex mt-1"
                                      style={{ gap: '2%' }}
                                    >
                                      <FormattedMessage id="executionDate" /> :{' '}
                                      <div>
                                        <p>
                                          <DateFormatter date={pd.start_date} />
                                        </p>
                                      </div>
                                    </div>
                                    {!pd.is_entire_day && (
                                      <p className="d-flex gp mt-1">
                                        <FormattedMessage id="time" />:
                                        <span style={{ marginLeft: '-6px' }}>
                                          <DateFormatter
                                            date={pd.start_date}
                                            hideDate
                                            showTime
                                          />
                                        </span>
                                      </p>
                                    )}
                                    {pd.is_entire_day && (
                                      <p style={{ lineHeight: '1.5rem' }}>
                                        <FormattedMessage id="executionDateMessage" />
                                      </p>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {orderDetails?.status?.name !== 'missed' && (
                <div className="row">
                  <div className="orderDetailButtonGroup">
                    {orderDetails?.status?.name === 'processing' && (
                      // {true && (
                      <button
                        type="button"
                        className=" TypeNegative-Rest-medium"
                        onClick={openRejectModal}
                      >
                        <FormattedMessage id="rejectOrder" />
                      </button>
                    )}

                    {orderDetails?.status?.name === 'processing' && (
                      // {orderDetails?.status.name === 'awaiting_completion' ||
                      //   (orderDetails?.status.name === 'scheduled' && (
                      <button
                        className=" Box-type-medium"
                        type="button"
                        onClick={handleAcceptOrder}
                      >
                        <FormattedMessage id="acceptOrder" />
                      </button>
                    )}

                    {(orderDetails?.status?.name === 'awaiting_completion' ||
                      orderDetails?.status?.name === 'scheduled') && (
                      // {true && (
                      <button
                        type="button"
                        className=" Box-type-medium"
                        onClick={() => {
                          setError('blank');
                          togglePopup();
                        }}
                      >
                        <FormattedMessage id="finish" />
                      </button>
                    )}

                    {(orderDetails?.status?.name === 'scheduled' || orderDetails?.status?.name === 'awaiting_completion') && (
                        <button
                            type="button"
                            className="TypeNegative-Rest-medium"
                            onClick={handleCancelOrderShowPopup}
                        >
                          <FormattedMessage id="cancelOrder" />
                        </button>
                    )}

                    {(orderDetails?.status?.name === 'scheduled') && (
                        <button
                            type="button"
                            className="TypeSecondary-Rest"
                            onClick={handleAcceptOrder}
                        >
                          <FormattedMessage id="updateOrderExecutionDate" />
                        </button>
                    )}
                  </div>
                </div>
              )}

              <AcceptedOrderPopup
                  show={acceptOrderObject.showAcceptOrderModal}
                  handleClose={closeAcceptOrderModal}
                  orderDetails={orderDetails}
                  preferredDates={orderData?.preferredDate}
                  okButtonAction={submitAcceptOrder}
                  staticTextChange={handleSelect}
                  cancelButtonAction={closeAcceptOrderModal}
                  handleCustomerNotAnswered={handleCustomerNotAnswered}
                  showSpinner={acceptOrderObject.showLoader}
                  acceptOrderErrorMsg={acceptOrderObject.acceptOrderError}
                  handleCheckbox={handleCheckbox}
                  okButtonDisabled={acceptOrderObject.acceptOrderCheckbox}
                  showCheckboxError={showCheckboxError}
                  useDisabled={false}
              />

              <CancelOrderPopup
                  show={showCancelModal}
                  handleClose={closeCancelModal}
                  approveAction={confirmCancelOrder}
                  cancellationCost={cancellationCosts}
              />

              <ModalBs
                show={showRejectModal}
                handleClose={closeRejectModal}
                title="rejectOrder"
                text="rejectModalBody"
                okButtonText="rejectOrder"
                okButtonAction={handleReject}
                cancelButtonText="cancel"
                cancelButtonAction={closeRejectModal}
                inputAction={handleRejectMessage}
                inputDefaultValue=" "
                showInputError={showRejectInputError}
                inputErrorMessage="required"
                textArea
              />

              <ModalCheckPayoutStatus
                show={showPaymentStatusModal}
                setShowPaymentStatusModal={setShowPaymentStatusModal}
                handleClose={closeAcceptOrderModal}
                title="checkPaymentStatus"
                showSpinner={paymentStatusCheck.isLoading}
                paymentStatusCheck={paymentStatusCheck}
              />

              {/* <ModalBs */}
              {/*  show={acceptOrderObject.showAcceptOrderModal} */}
              {/*  handleClose={closeAcceptOrderModal} */}
              {/*  title="acceptOrder" */}
              {/*  text="acceptOrderDateMessage" */}
              {/*  okButtonText="accept" */}
              {/*  okButtonAction={submitAcceptOrder} */}
              {/*  cancelButtonText="cancel" */}
              {/*  cancelButtonAction={closeAcceptOrderModal} */}
              {/*  staticText */}
              {/*  staticTextData={orderData?.preferredDate} */}
              {/*  staticTextChange={handleSelect} */}
              {/*  showSpinner={acceptOrderObject.showLoader} */}
              {/*  acceptOrderErrorMsg={acceptOrderObject.acceptOrderError} */}
              {/*  handleCheckbox={handleCheckbox} */}
              {/*  okButtonDisabled={acceptOrderObject.acceptOrderCheckbox} */}
              {/*  showCheckboxError={showCheckboxError} */}
              {/*  useDisabled={false} */}
              {/* /> */}

              <InvoiceModal
                showFinishPopup={showFinishPopup}
                togglePopup={togglePopup}
                handleFileChange={handleFileChange}
                error={error}
                setAmount={setAmount}
                handleFinish={handleFinish}
              />
              <input
                onChange={handleFileChange}
                className="d-none"
                type="file"
                ref={fileInputRef}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ minHeight: '85vh' }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>
              <p className="loaderText">
                {' '}
                <FormattedMessage
                  id="orderDetailLoader"
                  defaultMessage="Loading order details, please wait a moment."
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetail;
