import { OrdersList } from "../../Models/Orders.Model";
import { SET_ALL_ORDER_DATA, SET_ORDER_LIST, SET_ORDER_META, SET_PREFERRED_DATE } from "../constants";


// const initialState:OrdersList = {
//     ordersList: [{
//         id: 0,
//         user_id: "",
//         service_point_id: "",
//         department: "",
//         salutation: "",
//         name: "",
//         status: {name:"blank",id:0,date:""},
//         services: [{}],
//         payments: null,
//         additional_data: [{}],
//         address: Object,
//         phone: 0,
//         email: "",
//         comments: [{}],
//         preferred_dates:[{}],
//         create_dispute: false,
//         quote_request: false,
//         language: "",
//         quote_sendable: false,
//         quote_expiration: null,
//         quote_extendable: false,
//         quote_extension_max_date: "",
//         payment_method: "blank",
//         created_at: "",
//         updated_at: "",
//         meta: {}
//       }],
//     meta:{
//         total: 0,
//         limit: 0,
//         page: 0,
//         has_more_pages: false,
//         statuses: {
//           processing: 0,
//           scheduled: 0,
//           awaiting_completion: 0,
//           completed: 0,
//           cancelled: 0
//         }
//     },
//     preferredDate:[
//         {
//             description:"",
//             end_date: "",
//             start_date:""
//         }
//     ]
// }
const initialState:OrdersList = {
    ordersList: [],
    meta:{ },
    preferredDate:[ ]
}
export default (
    state: OrdersList = initialState,
    { type, payload }: any = {}
) => {
    switch (type) {
        case SET_ORDER_LIST:
            return { ...state, ordersList: payload };
        case SET_ORDER_META:
            return { ...state, meta: payload };
        case SET_ALL_ORDER_DATA:
            return { ...state,ordersList:payload.data.data,meta: payload.data.meta };
        case SET_PREFERRED_DATE:
            return {
                ...state, preferredDate:payload.data.data
            }
            default:
            return state;
    }
};
