import React, { useEffect } from 'react';
import { AuthProvider, TAuthConfig } from 'react-oauth2-code-pkce';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppRouter, { MagicRoute, PublicRoute } from './AppRouter';
import webSocket from './services/webSocket.service';
import { RootState } from './store';
import { UPDATE_SOCKET_STATUS } from './store/constants';
import LanguageWrapper from './utils/LanguageWrapper';
import auth from "./components/subComponents/Auth";

export function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.userReducer);
  const { selectedDepartment } = useSelector(
    (state: RootState) => state.departmentReducer
  );

  // Set the 2 variables here for me
  const magicLogin = localStorage.getItem('magicLogin');
  const magicLink = localStorage.getItem('magicLink');
  const authorizationEndpoint = magicLogin
      ? process.env.REACT_APP_OAUTH_AUTHORIZATION_MAGIC_ENDPOINT || ''
      : process.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT || '';
  const extraAuthParameters = magicLink
      ? {
        'login-signature': magicLink,
      }
      : undefined;
  const origin = localStorage.getItem('origin')!;
  const loginInProgress = localStorage.getItem('ROCP_loginInProgress');

  // show the magic login debug information
  // i expected that certain browsers are not fast enough with the localstorage
  console.log('Magic login debug', {
    "magicLogin": magicLogin,
    "magicLink": magicLink,
    "authorizationEndpoint": authorizationEndpoint,
    "extraAuthParameters": extraAuthParameters,
    "loginInProgress": loginInProgress,
  })

  const authConfig: TAuthConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authorizationEndpoint,
    // magicLogin === 'true'
    //   ? `${
    //       process.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT
    //     }${localStorage.getItem('magicLink')}`
    //   : process.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT || '',
    tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
    redirectUri: `${process.env.REACT_APP_URL}/oauth/callback`,
    scope: process.env.REACT_APP_SCOPE,
    extraAuthParameters,
    autoLogin: false,
    // preLogin: () => {if(!localStorage.getItem("origin"))localStorage.setItem("origin",window.location.href)},
    postLogin: () => {
      window.location.replace(origin);
      localStorage.removeItem('origin');
      if (magicLink) {
        localStorage.removeItem('magicLink');
      }
      if (magicLogin){
        localStorage.removeItem('magicLogin');
      }
    },
    logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT,
    // logoutRedirect: `http://localhost:3000/login`,
  };

  // useEffect(() => {
  //   // Initializing Web Socket
  //   if (token && Object.keys(selectedDepartment).length) {
  //     webSocket.initWebSocket(token, selectedDepartment.slug, (val: boolean) =>
  //       dispatch({ type: UPDATE_SOCKET_STATUS, payload: val })
  //     );
  //   }
  // }, [token, selectedDepartment]);

  return (
    <div>
      {window.location.href.includes('/invoices/payall') || window.location.href.includes('/register') ? (
        <LanguageWrapper>
          <Router>
            <PublicRoute />
          </Router>
        </LanguageWrapper>
      ) : window.location.href.includes('login-signature') ? (
        <AuthProvider authConfig={authConfig}>
          <LanguageWrapper>
            <Router>
              <MagicRoute />
            </Router>
          </LanguageWrapper>
        </AuthProvider>
      ) : (
        <AuthProvider authConfig={authConfig}>
          <LanguageWrapper>
            <div className="mainWrapper">
              <AppRouter />
            </div>
          </LanguageWrapper>
        </AuthProvider>
      )}
    </div>
  );
}

export default App;
