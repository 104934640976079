import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { loadStripe } from '@stripe/stripe-js';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import '../components/Layout.css';
import {
  downloadInvoices,
  exportInvoices,
  getInvoices,
  payInvoice,
} from '../store/actions/InvoicesActions';
import smDownload from './assets/sm_download.svg';
import smChevronDown from './assets/sm_chevron-down.svg';
import verticalDots from './assets/sm_dots-vertical.svg';
import Paginator from '../components/Paginator/Paginator';
import StatusTag from '../components/subComponents/StatusTag';
import DateFormatter from '../components/subComponents/DateFormatter';
import AlertSuccess from '../components/Alerts/AlertSuccess';
import AlertCancel from '../components/Alerts/AlertCancel';
import PriceFormatter from '../components/subComponents/PriceFormatter';
import DashboardInvoices from '../components/Dashboard/DashboardInvoices';
import usePayAll from '../customHooks/usePayAll';
import ModalBs from '../components/subComponents/ModalBs';
import { singleInvoice } from '../Models/InvoicesModel';
import { RootState } from '../store';
import AppSpinner from '../components/subComponents/AppSpinner';

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
);

const Invoices = () => {
  const userReducer = useSelector((state: RootState) => state.userReducer);
  const invoiceData = useSelector((state: RootState) => state.invoiceReducer);
  const initialLoader = useSelector(
    (state: RootState) => state.initialLoaderReducer
  );
  const appLoader = useSelector((state: any) => state.appSpinnerReducer);

  const dispatch: any = useDispatch();
  const [totalPayment, setTotalPayment] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [downloadpdf, setDownloadPdf] = React.useState<string[]>([]);
  const [payableIDs, setPayableIDs] = React.useState<string[]>([]);
  const [disabled, setDisabled] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState(false);
  const [exportMsg, setExportMsg] = React.useState(false);
  const [exportErrMsg, setExportErrMsg] = React.useState<any>('');
  // const [cancelExportMessage,setCancelExportMessage] = React.useState(false);
  const [cancelAlertMsg, setCancelAlertMsg] = React.useState(false);
  const [multiplePdf, setMultiplePdf] = React.useState<string[]>([]);
  const [showExportModal, setExportShowModal] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState(userReducer.profile.email);
  const [retryID, setRetryID] = React.useState<any>();
  const [retryDisabled, setRetryDisabled] = React.useState(false);
  const [retryIDs, setRetryIDs] = React.useState<any>();
  const { useAllPayment } = usePayAll();

  React.useEffect(() => {
    if (initialLoader.loaded === true) {
      setUserEmail(userReducer.profile.email);
      dispatch(getInvoices());
    }
    setTotalItems(0);
    setTotalPayment(0);
    if (!localStorage.getItem('srvrght')) setPayableIDs([]);
    setMultiplePdf([]);

    if (window.location.href.includes('success')) {
      setAlertMsg(true);
      window.history.pushState('', '', `${process.env.REACT_APP_URL}/finance`);
      if (localStorage.getItem('srvrght')) localStorage.removeItem('srvrght');
    } else if (window.location.href.includes('cancel')) {
      setCancelAlertMsg(true);
      // debugger
      if (localStorage.getItem('multiPayment') === 'true') {
        if (localStorage.getItem('srvrght') !== '') {
          setRetryIDs(localStorage.getItem('srvrght')?.split(','));
          setRetryID('pay-all');
        } else {
          setRetryIDs('');
          setRetryID('pay-all');
        }
      } else {
        setRetryID(localStorage.getItem('srvrght'));
      }
      // @ts-ignore

      //   setRetryIDs(localStorage.getItem('srvrght')?.split(','));
      // setRetryID(localStorage.getItem('srvrght'));

      window.history.pushState('', '', `${process.env.REACT_APP_URL}/finance`);
    }
    // else {
    //   if(localStorage.getItem('srvrght'))
    //   localStorage.removeItem('srvrght')
    // }
  }, [initialLoader]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleMultiDownload = () => {
    // console.log('iv', multiplePdf);
    const payload = {
      email: userEmail,
      ids: multiplePdf,
    };
    if (multiplePdf.length !== 0) {
      dispatch(
        exportInvoices(payload, (status: boolean, response: AxiosResponse) => {
          if (status) {
            // console.log('Resp', status, response);
            setExportMsg(true);
            setExportErrMsg('');
            closeModal();
            setUserEmail(userReducer.profile.email);
          } else {
            setExportErrMsg(response);
            setExportMsg(false);
            closeModal();
            setUserEmail(userReducer.profile.email);
            // setCancelExportMessage(true)
            // console.log('inst', status, response);
          }
        })
      );
    }
  };

  const handleSingleDownload = (id: string) => {
    dispatch(downloadInvoices(id));
  };
  const handleCheckbox = (e: any) => {
    const checkedItemPrice = invoiceData.invoiceList.find(
      (item: any) => item.id === e.target.value
    );
    if (e.target.checked) {
      // console.log('che', checkedItemPrice);
      setDownloadPdf([...downloadpdf, e.target.value]);
      if (checkedItemPrice.payable) {
        if (!payableIDs.includes(checkedItemPrice.id)) {
          setTotalItems(totalItems + 1);
          setTotalPayment(totalPayment + checkedItemPrice.total_items_price);
          setPayableIDs([...payableIDs, checkedItemPrice.id]);
        }
        // if (!multiplePdf.includes(checkedItemPrice.id))
        //   setMultiplePdf([...multiplePdf, checkedItemPrice.id]);
      }

      if (!multiplePdf.includes(checkedItemPrice.id))
        setMultiplePdf([...multiplePdf, checkedItemPrice.id]);
    } else {
      if (checkedItemPrice.payable) {
        setTotalItems(totalItems - 1);
        setTotalPayment(totalPayment - checkedItemPrice.total_items_price);
        const index = payableIDs.indexOf(checkedItemPrice.id);
        const temp = payableIDs;
        // console.log('index is', index);
        if (index !== -1) {
          temp.splice(index, 1);
          setPayableIDs(temp);
        }
      }
      if (multiplePdf.length !== 0) {
        const index = multiplePdf.indexOf(checkedItemPrice.id);
        if (index !== -1) {
          const temp = multiplePdf;
          temp.splice(index, 1);
          setMultiplePdf(temp);
        }
      }
      if (downloadpdf.length !== 0) {
        const index = downloadpdf.indexOf(checkedItemPrice.id);
        if (index !== -1) {
          const temp = downloadpdf;
          temp.splice(index, 1);
          setDownloadPdf(temp);
        }
      }
    }
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      const tempPdf = multiplePdf;
      const tempPayableID = payableIDs;
      let totalItemCount = totalItems;
      let totalItemPrice = totalPayment;
      for (let i = 0; i < invoiceData.invoiceList.length; i++) {
        // following check is for if invoice is payable
        if (invoiceData.invoiceList[i].payable) {
          // following check is to avoid entering already selected data
          if (!tempPayableID.includes(invoiceData.invoiceList[i].id)) {
            totalItemCount++;
            totalItemPrice += invoiceData.invoiceList[i].total_items_price;
            tempPayableID.push(invoiceData.invoiceList[i].id);
          }
        }
        setTotalItems(totalItemCount);
        setTotalPayment(totalItemPrice);
        setPayableIDs(tempPayableID);
        const checkbox = document.getElementById(invoiceData.invoiceList[i].id);
        //  @ts-ignore
        if (checkbox !== null) checkbox.checked = true; // This will check all checkboxes
        // Following check is to avoid already selected data in the array
        if (!tempPdf.includes(invoiceData.invoiceList[i].id))
          tempPdf.push(invoiceData.invoiceList[i].id);
      }
      setMultiplePdf(tempPdf);
    } else {
      // uncheck all
      setTotalItems(0);
      setTotalPayment(0);
      setPayableIDs([]);
      setMultiplePdf([]);

      for (let i = 0; i < invoiceData.invoiceList.length; i++) {
        const checkbox = document.getElementById(invoiceData.invoiceList[i].id);
        //  @ts-ignore
        if (checkbox !== null) checkbox.checked = false;
      }
    }
  };
  const retryPayment = (id: any) => {
    if (id === 'pay-all' && payableIDs.length !== 0) {
      localStorage.setItem('srvrght', payableIDs.toString());
      localStorage.setItem('multiPayment', 'true');
    } else if (id === 'pay-all' && payableIDs.length === 0) {
      localStorage.setItem('srvrght', '');
      localStorage.setItem('multiPayment', 'true');
    } else {
      localStorage.setItem('srvrght', id);
      localStorage.setItem('multiPayment', 'false');
    }
    setRetryDisabled(true);
    setDisabled(true);
    const body =
      id === 'pay-all' && retryIDs.length !== 0
        ? {
            cancel_url: `${window.location.origin}/finance/cancel`,
            success_url: `${window.location.origin}/finance/success`,
            ids: retryIDs,
          }
        : {
            cancel_url: `${window.location.origin}/finance/cancel`,
            success_url: `${window.location.origin}/finance/success`,
          };

    dispatch(
      payInvoice(id, body, (status, res) => {
        if (status) {
          const handleClick = async (checkoutID: string) => {
            const stripe = await stripePromise;

            const result = await stripe?.redirectToCheckout({
              sessionId: checkoutID,
            });

            if (result && result.error && result.error.message) {
              console.log('Payment err res', res);
            }
          };
          handleClick(res.id);
          setDisabled(false);
          setRetryDisabled(false);
        } else {
          // console.log('Payment res', res);
          setDisabled(false);
          setRetryDisabled(false);
        }
      })
    );
  };
  const onClickPay = (id: string) => {
    // console.log('payableids', payableIDs);

    if (id === 'pay-all' && payableIDs.length !== 0) {
      localStorage.setItem('srvrght', payableIDs.toString());
      localStorage.setItem('multiPayment', 'true');
    } else if (id === 'pay-all' && payableIDs.length === 0) {
      localStorage.setItem('srvrght', '');
      localStorage.setItem('multiPayment', 'true');
    } else {
      localStorage.setItem('srvrght', id);
      localStorage.setItem('multiPayment', 'false');
    }

    // setPayLoading(id);
    setRetryDisabled(true);
    setDisabled(true);
    const body =
      id === 'pay-all' && payableIDs.length !== 0
        ? {
            cancel_url: `${window.location.origin}/finance/cancel`,
            success_url: `${window.location.origin}/finance/success`,
            ids: payableIDs,
          }
        : {
            cancel_url: `${window.location.origin}/finance/cancel`,
            success_url: `${window.location.origin}/finance/success`,
          };

    dispatch(
      payInvoice(id, body, (status, res) => {
        if (status) {
          const handleClick = async (checkoutID: string) => {
            const stripe = await stripePromise;

            const result = await stripe?.redirectToCheckout({
              sessionId: checkoutID,
            });

            if (result && result.error && result.error.message) {
              console.log('Payment err res', res);
            }
          };

          window.location.href = res.data.url;

          // handleClick(res.id);
          setDisabled(false);
          setRetryDisabled(false);
        } else {
          // console.log('Payment res', res);
          setDisabled(false);
          setRetryDisabled(false);
        }
      })
    );
  };
  const popoverLeft = (
    <Popover id="popover-trigger-focus">
      <Popover.Body>
        <FormattedMessage id="nothingToPay" />
      </Popover.Body>
    </Popover>
  );

  const setEmail = (em: string) => {
    if (em === '') setUserEmail(userReducer.profile.email);
    setUserEmail(em);
  };
  const closeModal = () => setExportShowModal(false);
  const showModal = () => {
    if (multiplePdf.length !== 0) setExportShowModal(true);
  };
  console.log('payble', payableIDs);
  console.log('location url', window.location.pathname);

  const sortedInvoiceList = invoiceData.invoiceList
    ?.slice()
    .sort((a: any, b: any) => {
      const statusA = a.status.name.toLowerCase();
      const statusB = b.status.name.toLowerCase();
      if (statusA === 'draft' && statusB !== 'draft') {
        return -1;
      } else if (statusA !== 'draft' && statusB === 'draft') {
        return 1;
      } else {
        return 0;
      }
    });

  // console.log('invoiceData', invoiceData);
  // console.log('sxa', payableIDs, multiplePdf);
  // console.log("sdam",invoiceData.meta?.payable_invoices?.total)
  return (
    <div
      className={
        !(window.location.pathname === '/finance')
          ? 'containerHeightSetter components-card'
          : ''
      }
    >
      <div className="container">
        {alertMsg && <AlertSuccess id="successPaymentMessage" fixed />}
        {exportMsg && (
          <AlertSuccess
            id="exportSuccessMessage"
            payload={{ email: userEmail }}
            fixed={false}
          />
        )}
        {exportErrMsg !== '' && (
          <AlertCancel
            id="exportFailMessage"
            showButton={false}
            fixed={false}
            payload={{ msg: exportErrMsg }}
            action={() => {
              onClickPay(retryID);
            }}
            actionLabel="retryPayment"
            isDisabled={retryDisabled}
          />
        )}
        {cancelAlertMsg && (
          <AlertCancel
            id="cancelPaymentMessage"
            showButton
            action={() => {
              retryPayment(retryID);
            }}
            actionLabel="retryPayment"
            isDisabled={retryDisabled}
          />
        )}
        {userReducer.blockedStatus && (
          <AlertCancel
            id="blockedMsg"
            showButton
            action={useAllPayment}
            actionLabel="payAll"
          />
        )}
        <ModalBs
          show={showExportModal}
          handleClose={closeModal}
          title="exportModalTitle"
          text="exportModalText"
          okButtonText="download"
          okButtonAction={handleMultiDownload}
          cancelButtonText="cancel"
          cancelButtonAction={closeModal}
          inputAction={setEmail}
          inputDefaultValue={userReducer.profile.email}
        />
        <div className="row">
          <div className="col-12">
            <div className="heading-media">
              <div className="heading mb-0">
                <h2>
                  <FormattedMessage id="invoices" defaultMessage="Invoices" />
                </h2>
              </div>
              <div className="heading-right">
                <ol>
                  {/* <li>
                    <button
                      type="button"
                      className="box-btn box-btn-big"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <img src={smFilter} alt="" />
                    </button>
                  </li>
                  <li>
                    <button type="button" className="box-btn box-btn-big">
                      <img src={smSearch} alt="" />
                    </button>
                  </li> */}
                  <li>
                    <button
                      type="button"
                      className="Box-type-medium-second"
                      onClick={showModal}
                    >
                      {' '}
                      <span>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.667 9.333v3.334a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V9.333"
                            stroke="#1469a6"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.333 8 8 11.333 4.667 8M8 11.333V2"
                            stroke="#1469a6"
                            strokeWidth={1.5}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <p className="d-none d-sm-block"> Download</p>{' '}
                      {/* {downloadpdf !== '' && 1} */}
                    </button>
                  </li>
                  {/* <li>
                    {invoiceData.meta?.payable_invoices?.total !== 0 ? (
                      <button
                        type="button"
                        className={
                          disabled ? 'bnt-TypeDisabled' : 'Box-type-medium'
                        }
                        onClick={() => {
                          if (localStorage.getItem('srvrght'))
                            setPayableIDs([]);
                          onClickPay('pay-all');
                        }}
                        disabled={disabled}
                      >
                        {' '}
                        <FormattedMessage id="pay" />{' '}
                        {totalItems !== 0 ? (
                          <span>
                            ({totalItems}):{' '}
                            <PriceFormatter
                              amount={totalPayment}
                              symbol={invoiceData.invoiceList[0].currency_code}
                            />
                          </span>
                        ) : (
                          invoiceData.meta?.payable_invoices?.total && (
                            <span>
                              {' '}
                              ({
                                invoiceData.meta?.payable_invoices?.total
                              }):{' '}
                              <PriceFormatter
                                amount={
                                  invoiceData.meta.payable_invoices?.price
                                }
                                symbol={
                                  invoiceData.invoiceList[0]?.currency_code
                                }
                              />
                            </span>
                          )
                        )}
                      </button>
                    ) : (
                      <OverlayTrigger
                        trigger="focus"
                        placement="left"
                        overlay={popoverLeft}
                      >
                        <button type="button" className="bnt-TypeDisabled">
                          <FormattedMessage id="pay" />
                        </button>
                      </OverlayTrigger>
                    )}
                  </li> */}
                </ol>
                <div className="d-block d-sm-none mt-3">
                  <div className="HeightMedium  dropdown">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Sorted by Invoice date{' '}
                      <span>
                        <img src={smChevronDown} alt="" className="img-fluid" />
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a href="/#" className="dropdown-item StateDisabled ">
                          Option
                        </a>
                      </li>
                      <li>
                        <a href="/#" className="dropdown-item">
                          Option
                        </a>
                      </li>
                      <li>
                        <a href="/#" className="dropdown-item">
                          Option
                        </a>
                      </li>
                      <li>
                        <a href="/#" className="dropdown-item">
                          Option
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {appLoader.loaded.invoices ? (
              <div className="invoice-table">
                <div className="d-none d-sm-block">
                  <div className="table-responsive table-shadow bg-white">
                    <table className="table-striped .w-auto">
                      <thead>
                        <tr>
                          <th>
                            <input
                              id="mainCheckBox"
                              className="StateChecked "
                              type="checkbox"
                              value=""
                              onClick={handleCheckAll}
                            />
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="invoiceNumber"
                                defaultMessage="Invoice Number"
                              />
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="amount"
                                defaultMessage="Amount"
                              />
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="invoiceDate"
                                defaultMessage="Invoice Date"
                              />
                              {/* <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.667 8.666 8 12l3.333-3.334M8 12V4"
                                  stroke="#1469A6"
                                  strokeWidth={1.5}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span> */}
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              Status
                              {/* <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.667 8.666 8 12l3.333-3.334M8 12V4"
                                  stroke="#1469A6"
                                  strokeWidth={1.5}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span> */}
                            </p>
                          </th>
                          <th>
                            <p className="sorting ">
                              <FormattedMessage
                                id="expirationDate"
                                defaultMessage="Expiration date"
                              />
                              {/* <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.667 8.666 8 12l3.333-3.334M8 12V4"
                                  stroke="#1469A6"
                                  strokeWidth={1.5}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span> */}
                            </p>
                          </th>
                          <th>
                            <p className="sorting d-none d-lg-block">
                              <FormattedMessage
                                id="actions"
                                defaultMessage="Actions"
                              />
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedInvoiceList?.map((invoice: singleInvoice) => {
                          return (
                            <tr key={invoice.id}>
                              <td>
                                {invoice.status?.name !== 'draft' && (
                                  <input
                                    id={invoice.id}
                                    className="StateChecked "
                                    type="checkbox"
                                    value={invoice.id}
                                    onChange={handleCheckbox}
                                    style={{ cursor: 'pointer' }}
                                    defaultChecked={
                                      multiplePdf.length === 0
                                        ? payableIDs.includes(invoice.id)
                                        : multiplePdf.includes(invoice.id)
                                    }
                                  />
                                )}
                              </td>
                              <DashboardInvoices invoice={invoice} />
                              <td>
                                <div className="d-none d-lg-flex justify-content-start">
                                  {invoice.status?.name !== 'draft' && (
                                    <button
                                      type="button"
                                      className="me-3 box-btn"
                                      onClick={() =>
                                        handleSingleDownload(invoice.id)
                                      }
                                    >
                                      <img src={smDownload} alt="" />
                                    </button>
                                  )}
                                  {invoice.payable && (
                                      <button
                                        type="button"
                                        className={
                                          disabled
                                            ? 'bnt-TypeDisabled'
                                            : 'box-btn'
                                        }
                                        disabled={disabled}
                                        onClick={() => {
                                          onClickPay(invoice.id);
                                        }}
                                      >
                                        <FormattedMessage
                                          id="pay"
                                          defaultMessage="Pay"
                                        />
                                      </button>
                                    )}
                                </div>
                                <button
                                  type="button"
                                  className="box-btn d-flex d-lg-none"
                                >
                                  <img src={verticalDots} alt="" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-block d-sm-none">
                  {invoiceData.invoiceList?.map((invoice: singleInvoice) => {
                    return (
                      <div
                        key={invoice.id}
                        className="components-box components-box-second border-0 mb-2"
                      >
                        <div className="Header-row-card">
                          <div className="Header-row-card-left">
                            <input
                              className="StateChecked "
                              type="checkbox"
                              value={invoice.id}
                              onChange={handleCheckbox}
                            />
                          </div>
                          <div className="Header-row-card-right  bg-white">
                            <ul>
                              <li>
                                <a
                                  className="Some-link"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() =>
                                    handleSingleDownload(invoice.id)
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  {invoice.reference_id}
                                </a>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <span style={{ textTransform: 'capitalize' }}>
                                  <StatusTag status={invoice.status.name} />
                                </span>
                              </li>
                              <li>
                                <div className="label-cell">Invoice date </div>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <div className="number-text">
                                  {' '}
                                  <DateFormatter date={invoice.invoiced_at} />
                                </div>
                              </li>
                              <li>
                                <div className="label-cell">
                                  Expiration date
                                </div>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <div className="number-text">
                                  <DateFormatter date={invoice.due_at} />
                                </div>
                              </li>
                              <li>
                                <p className="Neutral-High-contrast">
                                  <PriceFormatter
                                    amount={invoice.total_items_price}
                                    symbol={
                                      invoiceData.invoiceList[0].currency_code
                                    }
                                  />
                                </p>
                              </li>
                              <li style={{ textAlign: 'right' }}>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleSingleDownload(invoice.id)
                                  }
                                  className="box-btn"
                                >
                                  <img src={smDownload} alt="" />
                                </button>{' '}
                                {/* {invoice.payable && (
                                  <button
                                    type="button"
                                    className={
                                      disabled ? 'bnt-TypeDisabled' : 'box-btn'
                                    }
                                    onClick={() => {
                                      onClickPay(invoice.id);
                                    }}
                                    disabled={disabled}
                                  >
                                    <FormattedMessage
                                      id="pay"
                                      defaultMessage="Pay"
                                    />
                                  </button>
                                )} */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="sorting-table">
                  <p className="sorting">
                    <FormattedMessage
                      id="resultsPerView"
                      defaultMessage="Results per view"
                    />
                    : <strong>&nbsp;25</strong>
                  </p>
                  <Paginator
                    meta={invoiceData.meta}
                    dataLength={invoiceData.invoiceList.length}
                    getPageData={getInvoices}
                  />
                </div>
              </div>
            ) : (
              <AppSpinner message="loading" height="45vh" />
            )}
          </div>
        </div>
      </div>
      {/* <div className="invoice-modal">
        <FinishInvoicesOrder />
      </div> */}
    </div>
  );
};

export default Invoices;
