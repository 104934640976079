import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import AlertWarning from '../Alerts/AlertWarning';
import AlertInfo from "../Alerts/AlertInfo";


const CancelOrderPopup = ({
    show,
    handleClose,
    approveAction,
    cancellationCost = null,
}: any) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>
                        <FormattedMessage id="cancelOrder" />
                    </h3>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {cancellationCost === null ? (
                    <div>
                        <Spinner style={{ marginLeft: '44%' }} /><br /><br />
                        <AlertInfo
                            id="cancelOrder.getCancellationEligibility"
                            style={{ marginTop: '40px' }}
                        />
                    </div>
                ) : (
                    cancellationCost.with_cancellation_costs ? (
                        <AlertWarning
                            id="cancelOrderConfirmMessageCustomerGetsInvoiced"
                            payload={{ amount: (cancellationCost.cancellation_costs / 100).toFixed(2) }}
                        />
                    ) : (
                        <AlertWarning
                            id="cancelOrderConfirmMessage"
                            style={{ marginBottom: '10px' }}
                        />
                    )
                )}
            </Modal.Body>

            {cancellationCost !== null ? (
                <Modal.Footer>
                    <button type="button" className='Box-type-medium' onClick={approveAction}>
                        <FormattedMessage id="accept" />
                    </button>
                </Modal.Footer>
            ) : null}
        </Modal>
    );
};

export default CancelOrderPopup;
