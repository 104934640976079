import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AuthContext, IAuthContext, TAuthConfig, TRefreshTokenExpiredEvent } from "react-oauth2-code-pkce"
import {
  setPartnerDepartmentStartAsync,
  setSelectedDepartment,
  setSelectedServicepoint,
  switchLanguage,
} from '../store/actions/DepartmentActions';

import logo from '../pages/assets/logo.svg';
import languageData from '../store/data';
import { LangContext } from '../utils/LanguageWrapper';
import useComponentVisible from '../customHooks/useComponentVisible';
import DashboardSideMenu from './Dashboard/DashboardSideMenu';
import smChevronDown from '../pages/assets/sm_chevron-down.svg';
import {DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST} from "../store/constants";


const NavBar = () => {

  const [showMenu, setShowMenu] = React.useState(false);
  const {token, loginInProgress} = useContext<IAuthContext>(AuthContext)

  React.useEffect(() => {
    if(!loginInProgress && token.length > 0){
      dispatch(setPartnerDepartmentStartAsync());
    }
  }, [loginInProgress, token]);


  const departmentReducer = useSelector((state: any) => state.departmentReducer);
  const userReducer = useSelector((state: any) => state.userReducer);
  const onboardingReducer = useSelector((state: any) => state.onboardingReducer)
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const MyContext = React.useContext(LangContext);

  const handleLanguage = (data: any) => {
    dispatch(switchLanguage(data));
    const ln = data.name;
    MyContext?.languageSetter(ln);
  };
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

  const handleAccountDeletion = () => {
      dispatch({ type: DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST, payload: {accountDeleteRequested: true} })
  }

  useEffect(()=>{
    if (departmentReducer.error && departmentReducer.error !== "") {
      navigate('/access-denied');
    }
  },[departmentReducer.error, navigate, dispatch])


  React.useEffect(()=>{
    if(userReducer.blockedStatus && window.location.href.includes("invoices")===false)
    navigate("invoices")
  },[userReducer])
  React.useEffect(() => {
    if (isComponentVisible === false) setShowMenu(false);
  }, [isComponentVisible]);
  const handleMenuVisiblity = () => {
      setIsComponentVisible(true);
    setShowMenu(!showMenu);
  }
  const popoverLeft = (
    <Popover id="popover-trigger-focus">
      <Popover.Body>
      <a className='Some-link' href="tel:+31882222050">088 2222 050</a>
      </Popover.Body>
    </Popover>
  );
  return <div style={{ position: 'sticky', top: '0', zIndex: '1' }}>
    <header className="header-second">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="header-inner">
              <div className="header-left">
                 <button
                  hidden={onboardingReducer.isOnboardingRequired}
                  type="button"
                  onClick={handleMenuVisiblity}
                  className="menu-btn"
                >
                  <p>
                    <span />
                    <span />
                    <span />
                  </p>
                </button>
                <div className="logo">
                  <Link to={onboardingReducer.isOnboardingRequired ? "#" : "/#" }>
                    <img
                      src={logo}
                      alt=""
                      className="img-fluid"
                      style={{ minWidth: '148px' }}
                    />
                  </Link>
                </div>
                {onboardingReducer.isOnboardingRequired ? (
                    <button
                        onClick={handleAccountDeletion}
                        type="button"
                        className="ms-3 TypeNegative-Rest"
                    >
                      <p className="d-none d-sm-block"><FormattedMessage id="deleteAccount"/></p>{' '}
                      {/* {downloadpdf !== '' && 1} */}
                    </button>) : ''}
              </div>
              <div className="header-right">
                <div className="service-list">
                  <ol>
                    <li className="d-none d-md-block">
                      <div className="HeightSmall dropdown">
                        <button
                            className="btn btn-secondary"
                            type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={departmentReducer.departmentsList.length<=1?{pointerEvents:"none"}:{}}
                        >
                          {departmentReducer.selectedDepartment?.name}
                          <span className={!departmentReducer.selectedDepartment.avatar&&departmentReducer.departmentsList.length<=1?"d-none":""}>
                            <img
                              src={
                               departmentReducer.departmentsList.length===1?
                               departmentReducer.selectedDepartment?.image?.small_png
                               :
                               smChevronDown
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {departmentReducer.departmentsList.map(
                            (dept: any) => <li
                                role="button"
                                key={dept.id}
                                onClick={() => {
                                  dispatch(setSelectedDepartment(dept));
                                }}
                              >
                                <span className="dropdown-item StateDisabled ">
                                  {dept.name}
                                  {dept.open_orders !== 0 && (
                                    <span style={{ color: 'grey' }}>
                                      &nbsp;&nbsp;{dept.open_orders}
                                    </span>
                                  )}
                                </span>
                              </li>
                          )}
                        </ul>
                      </div>
                    </li>
                    <li className="d-none d-md-block">
                      <div className="HeightSmall dropdown">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={departmentReducer.servicepointsList.length<=1?{pointerEvents:"none"}:{}}
                        >
                          {departmentReducer.selectedServicepoint?.name}
                          <span className={!departmentReducer.selectedServicepoint.avatar&&departmentReducer.servicepointsList.length<=1?"d-none":""}>
                            <img
                              src={
                                departmentReducer.servicepointsList.length===1?
                                  departmentReducer.selectedServicepoint?.avatar
                                  :
                                  smChevronDown
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {departmentReducer.servicepointsList.map(
                            (servicePoint: any) => <li
                                key={servicePoint.id}
                                role="button"
                                onClick={() => {
                                  dispatch(
                                    setSelectedServicepoint(servicePoint)
                                  );
                                }}
                              >
                                <Link
                                  className="dropdown-item StateDisabled "
                                  to="/#"
                                >
                                  {servicePoint.name}
                                </Link>
                              </li>
                          )}
                        </ul>
                      </div>
                    </li>
                  </ol>
                </div>
                <div className="language-list">
                  <ol className="d-none d-md-flex">
                    {languageData.map((data) => <li
                        key={data.languageId}
                        className={
                          userReducer.locale.ISO === data.ISO
                            ? 'active'
                            : ''
                        }
                      >
                        <button
                          type="button"
                          className="links a-to-btn"
                          onClick={() => {
                            handleLanguage(data);
                          }}
                        >
                          {data.ISO}
                        </button>
                      </li>)}
                  </ol>
                  <div className="HeightSmall dropdown d-block d-md-none">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {userReducer.locale.ISO}
                      <span>
                        <img
                          src={smChevronDown}
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {languageData.map((lang) => {
                        return <li key={lang.ISO}>
                            <button
                              type="button"
                              onClick={() => {
                                handleLanguage(lang);
                              }}
                              className={
                                userReducer.locale.ISO === lang.ISO
                                  ? 'links a-to-btn dropdown-item StateDisabled '
                                  : 'links a-to-btn dropdown-item '
                              }
                            >
                              {lang.ISO}
                            </button>
                          </li>;
                      })}
                      {/* <li>
                        <Link className="dropdown-item StateDisabled " to ="/#">
                          NL
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div
      ref={ref}
      className={
        showMenu ? 'sidenave table-shadow active' : 'sidenave table-shadow'
      }
    >
      <DashboardSideMenu setIsComponentVisible={setIsComponentVisible}/>
    </div>
  </div>;
};

export default NavBar;
