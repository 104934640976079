// @ts-nocheck
import axios from './Api';

export const doAPICallWithCallBack = (
  url,
  callBack,
  method = 'get',
  data: any = null
) => {
  axios[method](url, data)
    .then((response) => {
      callBack(true, response.data);
    })
    .catch((error) => callBack(false, error));
};

export const doApiCall = (
    method,
    url,
    data: any = null
) => {
    return axios[method](url, data);
};