// DEPARTMENT ACTIONS
export const DEPARTMENT_DATA = 'DEPARTMENT_DATA';
export const SELECTED_DEPARTMENT = 'SELECTED_DEPARTMENT';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const BLOCKED_DATA = 'BLOCKED_DATA'
export const DEBIT_STATUS = 'DEBIT_STATUS'
export const LOAD_PROFILE = 'LOAD_PROFILE'
export const UPDATE_SOCKET_STATUS = 'UPDATE_SOCKET_STATUS'
export const SUBSCRIPTION_REQUIRES_PAYMENT = 'SUBSCRIPTION_REQUIRES_PAYMENT'
export const SUBSCRIPTION_REQUIRES_SELECTION = 'SUBSCRIPTION_REQUIRES_SELECTION'
export const SET_CURRENT_ACTIVE_SUBSCRIPTION = 'SET_CURRENT_ACTIVE_SUBSCRIPTION'
// SERVICE DROPDOWN
export const SERVICEPOINT_DATA = 'SERVICEPOINT_DATA';
export const SELECTED_SERVICEPOINT = 'SELECTED_SERVICEPOINT';

export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';

// INVOICE ACTIONS
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_INVOICE_META = 'SET_INVOICE_META'
export const SET_ALL_INVOICE_DATA = 'SET_ALL_INVOICE_DATA'

// ORDER ACTIONS
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_ORDER_META = 'SET_ORDER_META'
export const SET_ALL_ORDER_DATA = 'SET_ALL_ORDER_DATA'
export const SET_PREFERRED_DATE = 'SET_PREFERRED_DATE'

// Employees ACTIONS
export const SET_EMPLOYEES_LIST = 'SET_EMPLOYEES_LIST';
export const SET_EMPLOYEES_META = 'SET_EMPLOYEES_META'
export const SET_ALL_EMPLOYEES_DATA = 'SET_ALL_EMPLOYEES_DATA'
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'

// Subscriptions ACTIONS
export const SET_SUBSCRIPTIONS_LIST = 'SET_SUBSCRIPTIONS_LIST';
export const SET_SUBSCRIPTIONS_META = 'SET_SUBSCRIPTIONS_META'
export const SET_ALL_SUBSCRIPTIONS_DATA = 'SET_ALL_SUBSCRIPTIONS_DATA'

// Services Actions
export const SET_SERVICES_DATA = 'SET_SERVICES_DATA'

// Reviews Actions
export const SET_REVIEWS_LIST = 'SET_REVIEWS_LIST';
export const SET_REVIEWS_META = 'SET_REVIEWS_META'
export const SET_ALL_REVIEWS_DATA = 'SET_ALL_REVIEWS_DATA'

// SET_INITIAL_LOADER
export const SET_INITIAL_LOADER = 'SET_INITIAL_LOADER'

// Monthly Performance Actions
export const SET_COMPLETED_ORDERS_REVENUE = 'SET_COMPLETED_ORDERS_REVENUE'
export const SET_COMPLETED_ORDERS_COUNT = 'SET_COMPLETED_ORDERS_COUNT'
export const SET_AVERAGE_ORDER_VALUE = 'SET_AVERAGE_ORDER_VALUE'
export const SET_ORDERS_DECLINED = 'SET_ORDERS_DECLINED'
export const SET_ORDERS_LATE_RESPONSE = 'SET_ORDERS_LATE_RESPONSE'
export const SET_REVIEWS_RECIEVED_COUNT = 'SET_REVIEWS_RECIEVED_COUNT'
export const SET_AVERAGE_REVIEWS_VALUE = 'SET_AVERAGE_REVIEWS_VALUE'
export const SET_TOTAL_ORDER_COMISSION = 'SET_TOTAL_ORDER_COMISSION'
export const SET_CANCELLED_ORDERS_COUNT = 'SET_CANCELLED_ORDERS_COUNT'
export const SET_ACCEPTED_ORDERS_COUNT = 'SET_ACCEPTED_ORDERS_COUNT'

// LOADER CONSTANTS
export const SET_APP_SPINNER_LOADER = 'SET_APP_SPINNER_LOADER'
export const SET_ORDERS_LOADER = 'SET_ORDERS_LOADER'
export const SET_INVOICES_LOADER = 'SET_INVOICES_LOADER'
export const SET_PAYOUTS_LOADER = 'SET_PAYOUTS_LOADER'
export const SET_MONTHLY_LOADER = 'SET_MONTHLY_LOADER'
export const SET_EMPLOYEES_LOADER = 'SET_EMPLOYEES_LOADER'
// PAYOUTS ACTIONS
export const GET_PAYOUTS = 'GET_PAYOUTS'

// ONBOARDING ACTIONS
export const REQUIRES_ONBOARDING = 'REQUIRES_ONBOARDING'
export const DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST = 'DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST'

// LOCATIONS ACTIONS
export const SET_AUTOCOMPLETE_DATA = 'SET_AUTOCOMPLETE_DATA'