import {AxiosResponse} from "axios";
import axios from '../../utils/Api';
import { SET_AUTOCOMPLETE_DATA } from '../constants';

export const autoCompleteLocation = (s : string, limit = 5) => (dispatch: any) => {
     return axios.get(`/location-service/autocomplete?query=${s}&limit=${limit}`)
         .then((response: AxiosResponse) => {
             dispatch({
                 type: SET_AUTOCOMPLETE_DATA,
                 payload: response.data.data,
             });
         })
         .catch((error) => {
             console.log(error)
         })
};
