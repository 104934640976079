import { v4 as uuidv4 } from 'uuid';
import axios from "../../utils/Api";
import {doApiCall, doAPICallWithCallBack} from "../../utils/helper";
import { SET_ALL_ORDER_DATA, SET_PREFERRED_DATE } from "../constants";
import { setOrdersLoader } from './AppSpinnerActions';

export const getOrders = (page = 1) => (dispatch: any) => {
  dispatch(setOrdersLoader(false))
  axios
    .get(`/orders?limit=5&page=${page}`)
    //  .get("/orders?statuses[]=awaiting_completion&statuses[]=scheduled&limit=10&page=1")

    .then((response: any) => {
      dispatch({
        type: SET_ALL_ORDER_DATA,
        payload: response,
      });
      dispatch(setOrdersLoader(true))
    })
    .catch((error) => {
      console.log('err', error);
      dispatch(setOrdersLoader(true))
    });
};

export const getOrderDetails = (id: string, callBack: any) => () => {
  return doAPICallWithCallBack(`orders/${id}`, callBack);
}

export const getCargoTypeData = (id: any, callBack: any) => () => {
  return doAPICallWithCallBack(`services/cargo-types/${id}`, callBack);
}

export const getTransportVehicleId = (id: any, callBack: any) => () => {
  return doAPICallWithCallBack(`services/transportation-vehicles/${id}`, callBack);
}

export const finishOrder = (id: string, data: any) => {
  return doApiCall('put', `orders/${id}/complete-order`, data)
}

export const cancelOrder = (id: string, callBack: any) => () => {
    return doAPICallWithCallBack(`orders/${id}/cancel`, callBack, 'put');
}

export const issueImageUploadLink = (data: any, callBack: any) => () => {
  const uid = uuidv4();
  return doAPICallWithCallBack(`orders/${data.id}/complete-order/issue-invoice-upload-link?id=${uid}&content-type=${data.type}&content-length-range=${data.size}`, callBack, 'put');
}

export const getFilteredOrders = (page = 1, status = ['']) => (dispatch: any) => {
  dispatch(setOrdersLoader(false))
  let statuses = '';
  if (status[0] !== "") {
    status.map((s: any) => statuses = `${statuses}statuses[]=${s}&`);
    // console.log('statuses', statuses);
    axios
      .get(`/orders?&${statuses}limit=5&page=${page}`)
      .then((response: any) => {
        dispatch({
          type: SET_ALL_ORDER_DATA,
          payload: response,
        });
  dispatch(setOrdersLoader(true))
      })
      .catch((error) => {
        console.log('err', error);
  dispatch(setOrdersLoader(true))
      });
  } else {
    axios
      .get(`/orders?limit=5&page=${page}`)
      //  .get("/orders?statuses[]=awaiting_completion&statuses[]=scheduled&limit=10&page=1")

      .then((response: any) => {
        dispatch({
          type: SET_ALL_ORDER_DATA,
          payload: response,
        });
      })
      .catch((error) => {
        console.log('err', error);
      });
  }
};

export const rejectOrders = (id:any,data:any,callback:any) => () => {
  return doAPICallWithCallBack(`orders/${id}/reject`, callback, 'put', data);
}
export const getPreferredDate = (id:any) => (dispatch:any) => {
  axios
  .get(`/orders/${id}/preferred-dates`)
  .then((res)=>{
    dispatch({type: SET_PREFERRED_DATE,payload:res})
    console.log("Resp",res)
  })
  .catch((err)=>{
    console.log("Err",err)
  })
 };

 export const acceptOrder = (id:number, data:any ) => {
  return axios.put(`orders/${id}/accept`, data);
 }

 export const updateExecutionDate = (id:number, data:any ) => {
     return axios.put(`orders/${id}`, data);
 }

 export const getCancellationCostsApply = (id:number) => {
     return axios.get(`orders/${id}/requires-cancellation-costs`);
 }

 // issue the payment link for the user
 export const issuePaymentLink = (id: number, successUrl: string, errorUrl: string, preferredDate: any) => {
  return axios.put(`orders/${id}/issue-lead-payment`, {
    success_url: successUrl,
    cancel_url: errorUrl,
    preferred_date: preferredDate
  });
 }

 export const checkPaymentStatus = (id: number, sessionId: string) => {
  return axios.get(`orders/${id}/check-lead-payment-status`, {
    params: {
      checkout_session_id: sessionId
    }
  });
 }
