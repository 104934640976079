import { InvoicesList } from "../../Models/InvoicesModel";
import { SET_AUTOCOMPLETE_DATA } from "../constants";


const initialState = {
    data: []
};

export default (
    state = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case SET_AUTOCOMPLETE_DATA:
            return { ...state, data: payload };
        default:
            return state;
    }
};
