import { InvoicesList } from "../../Models/InvoicesModel";
import { SET_ALL_INVOICE_DATA, SET_INVOICE_LIST, SET_INVOICE_META } from "../constants";


const initialState:InvoicesList = {
    invoiceList: [],
    meta:{}
};

export default (
    state: InvoicesList = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case SET_INVOICE_LIST:
            return { ...state, invoiceList: payload };
        case SET_INVOICE_META:
            return { ...state, meta: payload };
        case SET_ALL_INVOICE_DATA:
            return { invoiceList:payload.data.data,meta: payload.data.meta };
        default:
            return state;
    }
};
