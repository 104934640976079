import {
    REQUIRES_ONBOARDING,
    DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST,
} from '../constants';

const initialState = {
    isOnboardingRequired: false,
    onboardingSteps: [],
    accountDeleteRequested: false,
};

export default (
    state: any = initialState,
    { type, payload }: any = {}
) => {
    switch (type) {
        case REQUIRES_ONBOARDING:
            return {
                ...state,
                isOnboardingRequired: payload.onboardingSteps.length > 0,
                onboardingSteps: payload.onboardingSteps
            };
        case DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST:
            return {
                ...state,
                accountDeleteRequested: payload.accountDeleteRequested
            }
        default:
            return state;
    }
};