import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import AlertCancel from '../Alerts/AlertCancel';
import AlertWarning from '../Alerts/AlertWarning';
import { RootState } from '../../store';
import DateFormatter from './DateFormatter';

const AcceptedOrderPopup = ({
    show,
    handleClose,
    orderDetails,
    okButtonAction,
    cancelButtonAction,
    inputAction,
    inputDefaultValue = '',
    showInputError = false,
    inputErrorMessage = '',
    preferredDates = [],
    staticTextChange,
    handleCustomerNotAnswered,
    showSpinner = false,
    okButtonDisabled = false,
    handleCheckbox,
    textArea = false,
    showCloseButton = true,
    useDisabled,}: any) => {
    const handleCustomDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        staticTextChange(e);
    };

    const currentSelectedPreferredDate = orderDetails.preferred_dates?.find((date: any) => date.selected === true);
    const orderIsProcessing = orderDetails?.status?.name === 'processing';
    const orderIsScheduled = orderDetails?.status?.name === 'scheduled';

    if(orderIsScheduled) {
        okButtonDisabled = false;
    }

    return (
        // <Modal dialogClassName="acceptOrderModal" show={show} onHide={handleClose}>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton={showCloseButton}>
                <Modal.Title>
                    <h3>
                        <FormattedMessage id={orderDetails?.status?.name === 'processing' ? 'acceptOrder' : 'updateOrderExecutionDate'} />
                    </h3>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {showSpinner ? (
                    <Spinner style={{ marginLeft: '44%' }} />
                ) : (
                    <>
                        {inputDefaultValue !== '' &&
                            (textArea ? (
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={inputDefaultValue}
                                    aria-label="Username"
                                    className="mt-4 mb-2"
                                    onChange={(e) => {
                                        inputAction(e.target.value);
                                    }}
                                />
                            ) : (
                                <Form.Control
                                    placeholder={inputDefaultValue}
                                    aria-label="Username"
                                    className="mt-4 mb-2"
                                    onChange={(e) => {
                                        inputAction(e.target.value);
                                    }}
                                />
                            ))}
                        {showInputError && (
                            <p className="errorMsg">
                                * <FormattedMessage id={inputErrorMessage} />
                            </p>
                        )}

                            <div>

                                {orderDetails?.status?.name === 'processing' ? (
                                    <div>
                                        <div className="alert alert-primary" role="alert">
                                            <FormattedMessage id="acceptOrderPreferredDatesMessage" />
                                        </div>
                                        <ul className="list-group">
                                            {preferredDates.map((obj: any, index: number) => {
                                                return (
                                                    <li className="list-group-item">
                                                        <FormattedMessage
                                                            id="voorkeursdatum"
                                                            defaultMessage={`Voorkeursdatum ${index + 1}: {date}`}
                                                            values={{
                                                                date: (
                                                                    <DateFormatter noPTag date={obj.start_date} showTime />
                                                                ),
                                                            }}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="alert alert-primary" role="alert">
                                            Huidige uitvoerdatum: <DateFormatter noPTag
                                                                                 date={currentSelectedPreferredDate?.start_date}
                                                                                 showTime/>
                                        </div>
                                        <p>Neem contact op met: <strong>{orderDetails.salutation} {orderDetails.name}</strong> op <a href={"tel:" + orderDetails.phone}>{orderDetails.phone}</a> en bespreek samen de gewenste uitvoerdatum</p>
                                    </div>
                                )}

                                <div className={orderDetails?.status?.name === 'processing' ? "mt-5" : ""}>
                                    {(orderDetails?.status?.name === 'processing') && (
                                        <p><FormattedMessage id="selectExecutionDate"/></p>
                                    )}
                                    <input
                                        type="datetime-local"
                                        className="mt-3 form-control"
                                        style={{fontSize: '0.9rem'}}
                                        onChange={handleCustomDateChange}
                                        min={new Date().toISOString().slice(0, 16)}
                                    />
                                </div>

                                {(orderDetails?.status?.name === 'processing') && (
                                    <label
                                        htmlFor="acceptOrderBox"
                                        className="mt-3"
                                        style={{display: 'flex', gap: '4%', paddingLeft: '0'}}
                                    >
                                        <input
                                            id="acceptOrderBox"
                                            className="StateChecked"
                                            type="checkbox"
                                            onChange={handleCheckbox}
                                        />
                                        <div style={{cursor: 'pointer'}}>
                                            <FormattedMessage id="acceptTerms"/>
                                            <a
                                                href="https://vandaag.services/algemene-voorwaarden-bedrijven/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <FormattedMessage id="terms"/>
                                            </a>
                                        </div>
                                    </label>
                                )}
                            </div>

                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                {orderIsProcessing && (
                    <button
                        type="button"
                        className="TypeNegative-Rest-medium"
                        onClick={cancelButtonAction}
                    >
                        <FormattedMessage id="cancel"/>
                    </button>
                )}

                {/* {orderIsScheduled && ( */}
                {/*    <button */}
                {/*        type="button" */}
                {/*        className="TypeNegative-Rest-medium" */}
                {/*        onClick={handleCustomerNotAnswered} */}
                {/*    > */}
                {/*        <FormattedMessage id="customerNotAnswered"/> */}
                {/*    </button> */}
                {/* )} */}

                <button
                    type="button"
                    className={okButtonDisabled ? 'bnt-TypeDisabled ' : 'Box-type-medium'}
                    onClick={okButtonAction}
                    disabled={useDisabled && okButtonDisabled}
                >
                    <FormattedMessage id="accept"/>
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AcceptedOrderPopup;
