import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import AlertCancel from '../Alerts/AlertCancel';
import AlertWarning from '../Alerts/AlertWarning';
import { RootState } from '../../store';
import DateFormatter from './DateFormatter';

const ModalBs = ({
  show,
  handleClose,
  title,
  text,
  okButtonText,
  okButtonAction,
  cancelButtonText,
  cancelButtonAction,
  inputAction,
  inputDefaultValue = '',
  showInputError = false,
  inputErrorMessage = '',
  staticText = false,
  staticTextData = [],
  staticTextChange,
  showSpinner = false,
  acceptOrderErrorMsg = '',
  okButtonDisabled = false,
  handleCheckbox,
  showCancelButton = true,
  showAlert = false,
  alertText = '',
  alertDynamo = 'blank',
  textArea = false,
  showCloseButton = true,
  // showCheckboxError,
  useDisabled,
}: any) => {
  const orderData = useSelector((state: RootState) => state.orderReducer);
  const [showCustomDateSelector, setShowCustomDateSelector] = useState(false);
  const handleSelectChange = (e: any) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'custom') {
      setShowCustomDateSelector(true);
    } else {
      setShowCustomDateSelector(false);
      staticTextChange(e);
    }
  };
  const handleCustomDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    staticTextChange(e);
  };

  return (
    // <Modal dialogClassName="acceptOrderModal" show={show} onHide={handleClose}>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton={showCloseButton}>
        <Modal.Title>
          <h3>
            {' '}
            <FormattedMessage id={title} />
          </h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showSpinner ? (
          <Spinner style={{ marginLeft: '44%' }} />
        ) : (
          <>
            {showAlert ? (
              <span>
                <AlertWarning
                  id={alertText}
                  fixed={false}
                  payload={alertDynamo}
                />
              </span>
            ) : (
              <p>
                <FormattedMessage id={text} />
              </p>
            )}

            {inputDefaultValue !== '' &&
              (textArea ? (
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={inputDefaultValue}
                  aria-label="Username"
                  className="mt-4 mb-2"
                  onChange={(e) => {
                    inputAction(e.target.value);
                  }}
                />
              ) : (
                <Form.Control
                  placeholder={inputDefaultValue}
                  aria-label="Username"
                  className="mt-4 mb-2"
                  onChange={(e) => {
                    inputAction(e.target.value);
                  }}
                />
              ))}
            {showInputError && (
              <p className="errorMsg">
                * <FormattedMessage id={inputErrorMessage} />
              </p>
            )}
            {staticText && (
              <div>
                <Form.Select
                  size="sm"
                  className="mt-3"
                  style={{ fontSize: '0.9rem' }}
                  onChange={staticTextChange && handleSelectChange}
                >
                  <option value="" selected disabled>
                    select an option
                  </option>
                  {staticTextData?.map((obj: any) => {
                    return (
                      <option value={[obj.start_date, obj.end_date]}>
                        <div className="rejectModalText" key={obj.start_date}>
                          <DateFormatter date={obj.start_date} showTime />
                          {/* <span> {obj.description}</span> */}
                        </div>
                      </option>
                    );
                  })}
                  <option value="custom">select custom </option>
                </Form.Select>
                {showCustomDateSelector && (
                  <div>
                    <input
                      type="datetime-local"
                      className="mt-3 form-control"
                      style={{ fontSize: '0.9rem' }}
                      onChange={handleCustomDateChange}
                      min={new Date().toISOString().slice(0, 16)}
                    />
                  </div>
                )}
                <label
                  htmlFor="acceptOrderBox"
                  className="mt-3"
                  style={{ display: 'flex', gap: '4%', paddingLeft: '0' }}
                >
                  <input
                    id="acceptOrderBox"
                    className="StateChecked"
                    type="checkbox"
                    onChange={handleCheckbox}
                  />
                  <div style={{ cursor: 'pointer' }}>
                    <FormattedMessage id="acceptTerms" />
                    <a
                      href="https://vandaag.services/algemene-voorwaarden-bedrijven/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage id="terms" />
                    </a>
                  </div>
                </label>
                {/* {showCheckboxError&&<div className="mt-3">
                  <AlertCancel
                    id="acceptOrderModalError"
                    showButton={false}
                    action={null}
                    actionLabel="blank"
                    fixed
                  />
                </div>} */}
                {acceptOrderErrorMsg && (
                  <AlertCancel
                    id={acceptOrderErrorMsg}
                    showButton={false}
                    action={null}
                    actionLabel="blank"
                    fixed
                  />
                )}
              </div>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {showCancelButton && (
          <button
            type="button"
            className="TypeNegative-Rest-medium"
            onClick={cancelButtonAction}
          >
            <FormattedMessage id={cancelButtonText} />
          </button>
        )}
        <button
          type="button"
          className={okButtonDisabled ? 'bnt-TypeDisabled ' : 'Box-type-medium'}
          onClick={okButtonAction}
          disabled={useDisabled && okButtonDisabled}
        >
          <FormattedMessage id={okButtonText} />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBs;
