import {useContext} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import { UserTypes, departmentLocale } from "../../Models/UsersModel";
import { BLOCKED_DATA, DEBIT_STATUS, LOAD_PROFILE, SWITCH_LANGUAGE, UPDATE_SOCKET_STATUS, SUBSCRIPTION_REQUIRES_PAYMENT } from "../constants";
import languageData from "../data";

let locale: departmentLocale | string | null = localStorage.getItem('locale');
if (!locale) {
    const defaultLanguage = languageData[0];
    localStorage.setItem('locale', JSON.stringify(defaultLanguage));
    locale = defaultLanguage
} else {
    locale = JSON.parse(locale)
}

const initialState: UserTypes = {
    token: JSON.parse(<string>localStorage.getItem('ROCP_token')),
    locale,
    blockedStatus: false,
    isSocketConnected: false,
    subscriptionRequiresPayment: false,
    profile: {},
    debitStatus:""
};

export default (
    state: UserTypes = initialState,
    { type, payload }: any = {}
) => {
    switch (type) {
        case SUBSCRIPTION_REQUIRES_PAYMENT:
            return {
                ...state,
                subscriptionRequiresPayment: payload
            };
        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: payload,
            };
        case BLOCKED_DATA:
            return {
                ...state, blockedStatus: payload
            };
            case DEBIT_STATUS:
                return {
                    ...state, debitStatus: payload
                };
        case LOAD_PROFILE:
            return {
                ...state, profile: payload
            };
        case UPDATE_SOCKET_STATUS:
            return {
                ...state, isSocketConnected: payload
            };
        default:
            return state;
    }
};

// JSON.parse(<string>localStorage.getItem('token'))