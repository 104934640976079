import { Payouts } from "../../Models/PayoutsModel";
import { GET_PAYOUTS } from "../constants";


const initialState:Payouts = {
    payoutsList: [], 
    meta:{}
};

export default (
    state: Payouts = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case GET_PAYOUTS:
            return { payoutsList:payload.data.data,meta:payload.data.meta };
        default:
            return state;
    }
};
