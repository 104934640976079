import { doAPICallWithCallBack, doApiCall } from "../../utils/helper";


export const updateSettings = (data:any,callback:any) => () => {
  return doAPICallWithCallBack(`/settings`, callback, 'patch', data);
}
export const updateHours= (data:any,callback:any) => () => {
  return doAPICallWithCallBack(`/settings/opening-hours`, callback, 'patch', data);
}
export const uploadSettingsAvatar = (data:any, callBack: any) => () => {
  return doAPICallWithCallBack('/settings/avatar', callBack, 'post',data);
}
export const getUploadAvatarID = (callback:any) => () => {
  return doAPICallWithCallBack(`/settings/avatar`, callback, 'get');
}
export const uploadSettingsVideo = (data:any, callBack: any) => () => {
  return doAPICallWithCallBack('/settings/video', callBack, 'post',data);
}
export const getUploadVideoID = (callback:any) => () => {
  return doAPICallWithCallBack(`/settings/video`, callback, 'get');
}
export const deleteAccount = () => {
  return doApiCall('delete', `/delete-account`);
}
