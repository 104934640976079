import {AxiosError, AxiosResponse} from 'axios';
import { redirect } from "react-router-dom";
import { RequiresPlatformException } from '../../exceptions/RequiresPlatformException'; // Adjust the import path as needed
import axios from "../../utils/Api";
import {
    BLOCKED_DATA,
    DEBIT_STATUS,
    DEPARTMENT_DATA,
    DEPARTMENT_ERROR, REQUIRES_ONBOARDING,
    SELECTED_DEPARTMENT,
    SELECTED_SERVICEPOINT,
    SERVICEPOINT_DATA, SET_SERVICES_DATA,
    SUBSCRIPTION_REQUIRES_PAYMENT,
    SUBSCRIPTION_REQUIRES_SELECTION,
    SWITCH_LANGUAGE
} from "../constants";
import {setInitialLoader} from "./InitialLoaderActions";

interface ErrorResponse {
    message: string;
    error: string;
}

export const setSelectedDepartment = (department: any = null) => (dispatch: any) => {
    let departmentName = localStorage.getItem("department");
    if (department) {
        localStorage.setItem("department", department.slug);
        departmentName = department.slug;
    }
    if (departmentName) {
        dispatch({
            type: SELECTED_DEPARTMENT,
            payload: departmentName,
        });
    }
    dispatch(getAndSetServicepoint())
};

export function switchLanguage(locale: object) {
    localStorage.setItem('locale', JSON.stringify(locale));
    return {
        type: SWITCH_LANGUAGE,
        payload: locale
    };
}

const getBlockStatus = () => (dispatch:any) => {
    axios.get("/settings/status")
    .then(({data: {invoices, sepa_mandate_activated}}) => {
        dispatch({type: BLOCKED_DATA, payload: invoices.block_access});
        dispatch({type: DEBIT_STATUS, payload: sepa_mandate_activated});
      })
    .catch((err)=>{
        console.log(err)
    })
}

export const setSelectedServicepoint = (servicePoint: any = null) => (dispatch: any) => {
    dispatch(setInitialLoader(false))
    
    let servicepointId = localStorage.getItem('servicepoint');
    if (servicePoint) {
        localStorage.setItem("servicepoint", servicePoint.id);
        servicepointId = servicePoint.id
    }
    if (servicepointId) {
        dispatch({
            type: SELECTED_SERVICEPOINT,
            payload: servicepointId
        });
    }
    dispatch(getBlockStatus());
    dispatch(loadUserProfile())
    dispatch(getOnboarding())


    //     .then((response: AxiosResponse) => {
    //
    //     // means we have some onboarding details here.
    //     if(response.data.requires_onboarding.length) {
    //         dispatch({
    //             type: REQUIRES_ONBOARDING,
    //             payload: []
    //         });
    //     }
    //
    //     // ignore if we are already on the onboarding section
    //    if(window.location.pathname.startsWith('/onboarding')) {
    //        return;
    //    }
    //
    //     // subscription selection is first step
    //     if(response.data.requires_onboarding.includes('subscription')) {
    //         window.location.href = '/onboarding/subscriptions';
    //         return;
    //     }
    //
    //     // next step is service selection
    //     if(response.data.requires_onboarding.includes('services')) {
    //         window.location.href = '/onboarding/services';
    //     }
    // })
}

export const setPartnerDepartmentStartAsync = () => (dispatch: any) => {
    axios
        .get("/departments")
        .then((response: any) => {
            const defaultDepartment = response.data.data[0].slug;
            if (!localStorage.getItem("department")) {
                localStorage.setItem("department", defaultDepartment);
            }
            dispatch({
                type: DEPARTMENT_DATA,
                payload: response.data.data,
            })
            dispatch(setSelectedDepartment());
        })
        .catch((error) => {
            dispatch({
                type: DEPARTMENT_ERROR,
                payload: error
            })
        });
};

export const getAndSetServicepoint = () => (dispatch: any) => {
    axios
        .get('/access-check')
        .then(response => {
            dispatch({ type: SERVICEPOINT_DATA, payload: response.data?.data });
            let foundId = false;
            for (let i = 0; i < response.data.data.length; i++) {
                if (localStorage.getItem('servicepoint') === response.data.data[i].id) {
                    foundId = true;
                    break;
                }
            }
            if (!localStorage.getItem('servicepoint') || !foundId) {
                localStorage.setItem("servicepoint", response.data.data[0].id);
                dispatch(setSelectedServicepoint(response.data.data[0]));
            } else {
                dispatch(setSelectedServicepoint());
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                // TODO redirect to 403 page
                window.location.href = '/access-denied';
            }
        });
};

export const loadUserProfile = () => (dispatch:any) => {
    axios.get("/profile")
    .then((resp)=>{
        dispatch({
            type:"LOAD_PROFILE",
            payload:resp.data
        })
        dispatch(setInitialLoader(true))

    })
    .catch((err)=>{
        console.log("Res",err)
    })
}

export const getOnboarding = () => (dispatch: any) => {
    axios.get("/onboarding")
        .then((response:any)=>{
            dispatch({
                type: REQUIRES_ONBOARDING,
                payload: {
                    onboardingSteps: response.data.requires_onboarding
                }
            });
        })
        .catch((error)=>{
            console.log("err",error)
        })
};

export const loadDepartmentsPublic = () => {
    return axios.get('/system-service/departments');
}