import React, { useEffect, useState } from 'react';
import {AxiosResponse} from "axios";
import {useSelector} from "react-redux";
import axios from '../utils/Api';
import AlertWarning from "../components/Alerts/AlertWarning";

const Subscriptions = () => {

  const onboardingReducer = useSelector((state: any) => state.onboardingReducer)

  useEffect(() => {
    axios.get('/settings/stripe/client-platform-secret', {
      params: {
        redirect_url: window.location.origin,
        onboarding: true
      },
    }).then((response: AxiosResponse) => {

      // navigate the user away from here if there is a portal link
      if(response.data.data.portal_link) {
        window.location.href = response.data.data.portal_link;
        return;
      }

      // Create Stripe pricing table element after script has loaded
      const stripeElement = document.createElement('stripe-pricing-table');
      stripeElement.setAttribute('pricing-table-id', response.data.data.pricing_table_id);
      stripeElement.setAttribute('publishable-key', response.data.data.publishable_key);
      stripeElement.setAttribute('client-reference-id', response.data.data.client_reference_id);
      stripeElement.setAttribute('customer-session-client-secret', response.data.data.customer_session_client_secret);

      const container = document.getElementById('stripe-container');
      if (container) {
        container.appendChild(stripeElement);
      }
    }).catch((err: any) => {
      console.log('error', err);
    });
    }, []);

  return (
      <div>
        <div className="stripe-container">
          <div className="container">
            {onboardingReducer.isOnboardingRequired && (
                <AlertWarning id="subscriptionRequiredToAccess" />
            )}
          </div>
          <div id="stripe-container"/>
        </div>
      </div>
  );
};

export default Subscriptions;
