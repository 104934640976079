import { SET_INITIAL_LOADER } from "../constants";


const initialState:{loaded:boolean} = {
    loaded: false, 
};

export default (
    state:{loaded:boolean} = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case SET_INITIAL_LOADER:
            return { loaded:payload };
        default:
            return state;
    }
};
