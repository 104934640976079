import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios, {AxiosError, AxiosResponse} from 'axios';
import { Spinner } from 'react-bootstrap';
import AppSpinner from "./subComponents/AppSpinner";
import {autoCompleteLocation} from "../store/actions/LocationServiceAction";
import {loadDepartmentsPublic} from "../store/actions/DepartmentActions";
import {register} from "../store/actions/RegistrationActions";

interface FormData {
    name: string;
    email: string;
    phone: string;
    address_id: string;
    street_number: string;
    department: string;
    coc: string;
    subscription_id?: string;
    redirect_location: string;
}

interface Errors {
    [key: string]: string;
}

interface AddressOption {
    id: string;
    label: string;
}

const RegistrationForm: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch: any = useDispatch();
    const [addressLabel, setAddressLabel] = useState<string>('');
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phone: '',
        address_id: '',
        street_number: '',
        department: '',
        coc: '',
        subscription_id: '',
        redirect_location: window.location.origin + "/magic-login?login-signature={login-signature}",
    });
    const addressOptions = useSelector((state: any) => state.locationServiceReducer.data)
    const [departmentOptions, setDepartmentOptions] = useState<any[]>([]);
    const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
    const [addressLoading, setAddressLoading] = useState<boolean>(false);
    const addressSearchTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const autocompleteRef = useRef<HTMLUListElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<Errors>({});
    const [globalError, setGlobalError] = useState<string | null>(null);
    const [loadingMessages] = useState<string[]>([
        "Be prepared for something amazing",
        "We are building your future",
        "Setting up your dashboard"
    ]);
    const [currentLoadingMessage, setCurrentLoadingMessage] = useState<number>(0);

    useEffect(() => {
        loadDepartmentsPublic().then((response: AxiosResponse) => {
            setDepartmentOptions(response.data.data);
        }).catch((error) => {
            console.error('Error loading departments:', error);
        });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target as Node)) {
                setShowAutocomplete(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const newFormData: Partial<FormData> = {};

        query.forEach((value, key) => {
            newFormData[key as keyof FormData] = value; // Map query string to form fields
        });

        if (Object.keys(newFormData).length > 0) {
            setFormData(prevData => ({
                ...prevData,
                ...newFormData
            }));
        }
    }, [location.search]);


    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setCurrentLoadingMessage((prev) => (prev + 1) % loadingMessages.length);
            }, 3000);
            return () => clearInterval(interval);
        }

    }, [loading, loadingMessages]);


    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        if (name === 'address_id' && e.target instanceof HTMLInputElement) {
            setAddressLabel(value);  // Update addressLabel as well
            handleAddressSearch(value);
        }
    };

    const handleAddressSearch = (query: string) => {
        if (addressSearchTimeout.current) {
            clearTimeout(addressSearchTimeout.current);
        }

        addressSearchTimeout.current = setTimeout(() => {
            setAddressLoading(true);
            dispatch(autoCompleteLocation(query)).then(() => {
                setShowAutocomplete(true);
            }).finally(() => {
                setAddressLoading(false);
            });
        }, 800);
    };

    const handleSelectAddress = (address_id: string, label: string) => {
        const selectedAddress = addressOptions.find((option: AddressOption) => option.id === address_id);
        setFormData(prevData => ({
            ...prevData,
            address_id,
            street_number: selectedAddress?.street_number || ''
        }));
        setAddressLabel(label);
        setShowAutocomplete(false);
    };

    const handleSubmit = async (e?: FormEvent) => {
        if (e) e.preventDefault();
        setLoading(true);
        setGlobalError(null);

        register(formData).then((response: AxiosResponse) => {
            window.location.href = response.data.data.url;
        }).catch((error: AxiosError) => {
            console.log(error);

            // stop the loading of the spinner
            setLoading(false);

            // const data = exception.response?.data as ErrorResponseData;
            //
            if (axios.isAxiosError(error) && error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
                return;
            }

            if(axios.isAxiosError(error) && error.response){
                // todo show a global error message here
                setGlobalError(error.message);
            } else {
                setGlobalError('An unexpected error occurred. Please try again later.');
            }
        });
    };

    if (loading) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center vh-100">
                <AppSpinner />
                <p className="mt-4 text-lg">{loadingMessages[currentLoadingMessage]}</p>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Registration Form</h2>
            {globalError && (
                <div className="alert alert-danger">
                    {globalError}
                </div>
            )}
            {Object.keys(errors).length > 0 && (
                <div className="alert alert-danger">
                    Please correct the errors below.
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="coc" className="form-label">Chamber Of Commerce</label>
                    <input
                        id="coc"
                        name="coc"
                        type="text"
                        className={`form-control ${errors.coc ? 'is-invalid' : ''}`}
                        value={formData.coc}
                        onChange={handleInputChange}
                        placeholder="Chamber Of Commerce"
                    />
                    {errors.coc && <div className="invalid-feedback">{errors.coc}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">Company Name</label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Company Name"
                    />
                    {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone</label>
                    <input
                        id="phone"
                        name="phone"
                        type="text"
                        className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Phone"
                    />
                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                </div>
                <div className="row mb-3">
                    <div className="col-md-8">
                        <label htmlFor="address_id" className="form-label">Zip Code</label>
                        <input
                            id="address_id"
                            name="address_id"
                            type="text"
                            className={`form-control ${errors.address_id ? 'is-invalid' : ''}`}
                            value={addressLabel}
                            onChange={handleInputChange}
                            placeholder="1234AA 123"
                            disabled={addressLoading}
                        />
                        {addressLoading && <Spinner animation="border" role="status" variant="primary"/>}
                        {showAutocomplete && addressOptions.length > 0 && (
                            <ul className="list-group position-absolute w-100" ref={autocompleteRef}>
                                {addressOptions.map((option: AddressOption) => (
                                    <li
                                        key={option.id}
                                        className="list-group-item list-group-item-action"
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => handleSelectAddress(option.id, option.label)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSelectAddress(option.id, option.label);
                                            }
                                        }}
                                    >
                                        {option.label}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {errors.address_id && <div className="invalid-feedback">{errors.address_id}</div>}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="street_number" className="form-label">Street Number</label>
                        <input
                            id="street_number"
                            name="street_number"
                            type="text"
                            className={`form-control ${errors.street_number ? 'is-invalid' : ''}`}
                            value={formData.street_number}
                            onChange={handleInputChange}
                            placeholder="Street Number"
                        />
                        {errors.street_number && <div className="invalid-feedback">{errors.street_number}</div>}
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="department" className="form-label">Department</label>
                    <select
                        id="department"
                        name="department"
                        className={`form-select ${errors.department ? 'is-invalid' : ''}`}
                        value={formData.department}
                        onChange={handleInputChange} // Use handleInputChange here
                    >
                        <option value="">Selecteer branch</option>
                        {Array.isArray(departmentOptions) && departmentOptions.map((department: any) => (
                            <option key={department.id} value={department.slug}>
                                {department.name}
                            </option>
                        ))}
                    </select>
                    {errors.department && <div className="invalid-feedback">{errors.department}</div>}
                </div>
                <button type="submit" className="btn btn-primary w-100">Maak Account Aan</button>
                <input
                    type="hidden"
                    name="subscription_id"
                    value={formData.subscription_id}
                />
            </form>
        </div>
    );
};

export default RegistrationForm;
