import { SET_ALL_REVIEWS_DATA, SET_REVIEWS_LIST, SET_REVIEWS_META } from "../constants";


const initialState = {
    reviewsList: [],
    meta:[]
};

export default (
    state: any = initialState,
    { type, payload }: any= {}
) => {
    switch (type) {
        case SET_REVIEWS_LIST:
            return { ...state, reviewsList: payload };
        case SET_REVIEWS_META:
            return { ...state, meta: payload };
        case SET_ALL_REVIEWS_DATA:
            return { invoiceList:payload.data.data,meta: payload.data.meta };
        default:
            return state;
    }
};
