import React, {useContext, useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {AxiosError, AxiosResponse} from "axios";
import Services from "../pages/Services";
import Subscriptions from "../pages/Subscriptions";
import StripeConnectOnboarding from "../pages/StripeConnectOnboarding";
import ModalBs from "./subComponents/ModalBs";
import {DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST} from "../store/constants";
import {deleteAccount} from "../store/actions/SettingsActions";

const Onboarding = () => {
    const dispatch = useDispatch();
    const onboardingReducer = useSelector((state: any) => state.onboardingReducer);
    const [currentStep, setCurrentStep] = useState<string | null>(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [cancelAccountAlert, setCancelAccountAlert] = useState<string | null>(null);
    const {logOut} = useContext<IAuthContext>(AuthContext)

    const closeCancelModal = () => {
        dispatch({ type: DELETE_ACCOUNT_FROM_ONBOARDING_REQUEST, payload: {accountDeleteRequested: false}})
    };

    const handleDeleteAccount = () => {
        setShowSpinner(true);
        deleteAccount().then((response: AxiosResponse) => {
            setShowSpinner(false);
            handleLogout();
        }).catch((error: AxiosError) => {
            setShowSpinner(false);
            setCancelAccountAlert('errorDeletingAccountContactSupport');
        });
    }

    const handleLogout = () => {
        logOut();
    }

    useEffect(() => {
        // This effect will run whenever onboardingReducer changes
        console.log("Onboarding steps changed:", onboardingReducer.onboardingSteps);

        if (onboardingReducer.onboardingSteps.includes('services')) {
            setCurrentStep('services');
        } else if (onboardingReducer.onboardingSteps.includes('subscription')) {
            setCurrentStep('subscription');
        } else if (onboardingReducer.onboardingSteps.includes('stripe-connect')) {
            setCurrentStep('stripe-connect');
        } else {
            setCurrentStep(null);
        }
    }, [onboardingReducer.onboardingSteps]);

    // Function to render the appropriate component
    const renderStepComponent = () => {
        switch (currentStep) {
            case 'subscription':
                return <Subscriptions />;
            case 'services':
                return <Services />;
            case 'stripe-connect':
                return <StripeConnectOnboarding />;
            default:
                return <div>Onboarding has been completed</div>;
        }
    };

    return (
        <div>
            {renderStepComponent()}
            <ModalBs
                show={onboardingReducer.accountDeleteRequested}
                handleClose={closeCancelModal}
                showSpinner={showSpinner}
                title="deleteAccount"
                text="deleteAccountConfirmText"
                alertText={cancelAccountAlert}
                showAlert={cancelAccountAlert != null}
                okButtonText={cancelAccountAlert != null ? "logout" : "deleteAccountOkButtonText"}
                okButtonAction={cancelAccountAlert != null ? handleLogout : handleDeleteAccount}
                showCancelButton={cancelAccountAlert === null}
                showCloseButton={cancelAccountAlert === null}
                cancelButtonText="deleteAccountCancelButtonText"
                cancelButtonAction={closeCancelModal}
            />
        </div>
    );
}

export default Onboarding;